// src/PatientContext.js
import React, {
    createContext,
    useCallback,
    useEffect,
    useMemo,
    useReducer,
} from 'react';
import { updateServerStatus } from '../../helpers/serverConnectionStatus';
import { useBeforeUnload } from '../../helpers/useBeforeUnload';
import { withSideEffect } from '../../helpers/withSideEffect';
import { patientReducer } from './PatientReducer';
import { useRESTRequest } from './serverUtils';

// This is used to retrieve all patient IDs from the database
// so that they can be displayed in the dropdown menu in the header.
// Currently, we are not saving any other data from this query,
// and opting to retrieve specific patient data again when an ID
// is selected from the dropdown. This is to avoid storing too much
// data in the client application.
export async function getAllPatients(makeRESTRequest) {
    const patients = await makeRESTRequest('GET', '/patients');
    return patients || [];
}

// Context for values that should be synced to a patient on the sever.
export const PatientContext = createContext();

export function emptyPhysicianInput() {
    return {
        treatment: '',
        diagnosisNotes: '',
        physicianPatientNotes: '',
        physicalExaminationNotes: '',
        epicrisis: '',
        anamnesis: '',
        diagnoses: [],
    };
}

export const PatientProvider = ({ children }) => {
    const [state, reducerDispatch] = useReducer(patientReducer, {
        physicianInput: emptyPhysicianInput(),
    });

    // Every time the patient is updated via the `dispatch()` function,
    // check the server status to see if we're still connected.
    // This should catch errors within the first few keypresses.
    // Automatically debounced behind the scenes.
    const dispatch = useMemo(
        () =>
            withSideEffect(reducerDispatch, () => {
                updateServerStatus();
            }),
        [reducerDispatch]
    );

    // This updates when the language is changed.
    const makeRESTRequest = useRESTRequest();

    // This fires when the language is changed.
    // That's OK for now because we always save data
    // before changing the language.
    useEffect(() => {
        const fetchData = () => {
            makeRESTRequest(
                'GET',
                `/patients/${state.patientId}/physician-input`
            ).then((data) => {
                if (data && data.physicianInput) {
                    dispatch({
                        type: 'SET_PHYSICIAN_INPUT',
                        payload: data.physicianInput,
                    });
                }
            });
        };

        if (state.patientId) {
            fetchData();
        }
    }, [state.patientId, makeRESTRequest, dispatch]);

    useBeforeUnload(
        useCallback(
            (ev) => {
                if (state.physicianInput.dirty) {
                    ev.returnValue = 'Unsaved changes';
                }
            },
            [state.physicianInput.dirty]
        )
    );

    return (
        <PatientContext.Provider
            value={{
                state,
                dispatch,
            }}
        >
            {children}
        </PatientContext.Provider>
    );
};
