import React from 'react';
import BenefitsImage1 from '../../../assets/benefitsSectionImage1.png';
import BenefitsImage2 from '../../../assets/benefitsSectionImage2.png';
import BenefitsImage4 from '../../../assets/benefitsSectionImage4.png';

const BenefitsSection = () => {
    return (
        <section className="section benefits-section">
            <div className="text-row">
                <h2>What to expect when you join</h2>
            </div>
            <div className="image-row">
                <div className="image-column">
                    <img
                        src={BenefitsImage1}
                        alt="Parallelize Tasks"
                        className="benefit-image"
                    />
                    <div className="benefit-description">
                        <h2>Parallelize Tasks</h2>
                        <br></br>
                        <p>
                            Documentation is completed in real time during the
                            patient visit. Simply review and save documentation
                            to the EMR using an intuitive copy-paste interface.
                        </p>
                    </div>
                </div>
                <div className="image-column">
                    <img
                        src={BenefitsImage2}
                        alt="Secure Data Handling"
                        className="benefit-image"
                    />
                    <div className="benefit-description">
                        <h2>Secure Data Handling</h2>
                        <br></br>
                        <p>
                            Doq Copilot is hosted on private servers located in
                            the country. Data is encrypted at all times, meaning
                            only the doctor can see patient info.
                        </p>
                    </div>
                </div>
                <div className="image-column">
                    <img
                        src={BenefitsImage4}
                        alt="Language Agnostic"
                        className="benefit-image"
                    />
                    <div className="benefit-description">
                        <h2>Language Agnostic</h2>
                        <br></br>
                        <p>
                            Language is not a barrier, it&#39;s a key tool. Doq
                            Copilot collaborates in more than 100 languages,
                            providing instant translation, correction & writing
                            suggestion.
                        </p>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default BenefitsSection;
