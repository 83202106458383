import React, { useState } from 'react';

export const AufenthaltToolbox = () => {
    const [tool, setTool] = useState('tool1');

    const handleToolSelection = (toolSelection) => {
        setTool(toolSelection);
    };

    return (
        <div className="toolbox-content">
            <div className="tool-selector">
                <button
                    className="visite"
                    onClick={() => handleToolSelection('tool1')}
                >
                    Visite
                </button>
                <button
                    className="oberarztvisite"
                    onClick={() => handleToolSelection('tool2')}
                >
                    Oberarztvisite
                </button>
                <button
                    className="chefarztvisite"
                    onClick={() => handleToolSelection('tool3')}
                >
                    ChefarztVisite
                </button>
                <button
                    className="to-do-fuer-pflege"
                    onClick={() => handleToolSelection('tool4')}
                >
                    To-Do für Pflege
                </button>
                <button
                    className="freitext"
                    onClick={() => handleToolSelection('tool5')}
                >
                    Freitext
                </button>
                <button
                    className="auffaellige-blutwerte"
                    onClick={() => handleToolSelection('tool6')}
                >
                    Auffällige Blutwerte
                </button>
                <button
                    className="medikamenten-aenderungen"
                    onClick={() => handleToolSelection('tool7')}
                >
                    Medikamenten Änderungen
                </button>
                <button
                    className="befunde"
                    onClick={() => handleToolSelection('tool8')}
                >
                    Befunde
                </button>
                <button
                    className="ris-befunde"
                    onClick={() => handleToolSelection('tool9')}
                >
                    RIS Befunde
                </button>
            </div>
            <div className="workspace">
                {tool == 'tool1' && <p>Visite</p>}
                {tool == 'tool2' && <p>Oberarztvisite</p>}
                {tool == 'tool3' && <p>ChefarztVisite</p>}
                {tool == 'tool4' && <p>To-Do für Pflege</p>}
                {tool == 'tool5' && <p>Freitext</p>}
                {tool == 'tool6' && <p>Auffällige Blutwerte</p>}
                {tool == 'tool7' && <p>Medikamenten Änderungen</p>}
                {tool == 'tool8' && <p>Befunde</p>}
                {tool == 'tool9' && <p>RIS Befunde</p>}
            </div>
        </div>
    );
};
