// Context to manage state that needs to be shared across dialogue card,
// between summary view and transcript items view.

import { createContext, useCallback, useState } from 'react';

export const DialogueContext = createContext();

export function DialogueProvider({ children }) {
    // State for server response, which is identical
    // across a few dialogue-related endpoints.
    // The convenience here is that read and update
    // responses can all be plugged into the same handler.
    const [serverResponse, setServerResponseState] = useState(undefined);

    // State for whether processing is stale.
    // Processing is stale when the dialogue transcript items
    // have been updated, but the dialogue transcript summary has not.
    const [processingIsStale, setProcessingIsStale] = useState(false);

    // The setter we provide also marks processing as stale automatically.
    const setServerResponse = useCallback(
        (value) => {
            setServerResponseState(value);
            setProcessingIsStale(true);
        },
        [setServerResponseState, setProcessingIsStale]
    );

    const value = {
        serverResponse,
        setServerResponse,
        processingIsStale,
        setProcessingIsStale,
    };

    return (
        <DialogueContext.Provider value={value}>
            {children}
        </DialogueContext.Provider>
    );
}
