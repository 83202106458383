import React, { useContext, useRef } from 'react';
import { ModalRenderTarget } from '../Dashboard/ModalContext';
import { PatientContext } from '../Dashboard/PatientContext';
import { usePatientData } from '../../fetchPatientDataHook';
import { CopyButton } from './CopyButton';
import { useTranslation } from 'react-i18next';
import { ZoomButton } from '../Dashboard/OutputCardHeader';
import { useSessionStorage } from '../../helpers/useStorage';

// C-Style enum to prevent typos. Translated via i18n.
const DATA_SOURCE_ANAMNESIS = 'anamnesis';
const DATA_SOURCE_EPICRISIS = 'epicrisis';
const DATA_SOURCE_PHYSICAL_EXAMINATION = 'physicalExamination';
const DATA_SOURCE_DIAGNOSIS = 'diagnosis';
const DATA_SOURCE_THERAPY = 'therapy';

const DATA_SOURCES = [
    DATA_SOURCE_ANAMNESIS,
    DATA_SOURCE_PHYSICAL_EXAMINATION,
    DATA_SOURCE_DIAGNOSIS,
    DATA_SOURCE_THERAPY,
    DATA_SOURCE_EPICRISIS,
];

function dataSourcesToSummary(dataSources, physicianInput, patientData, t) {
    const summarySections = [];
    if (dataSources[DATA_SOURCE_ANAMNESIS]) {
        const anamnesis = patientData.data || '...';
        summarySections.push(`${t('anamnesis')}:\n${anamnesis}`);
    }
    if (dataSources[DATA_SOURCE_PHYSICAL_EXAMINATION]) {
        summarySections.push(
            `${t('physicalExamination')}:\n` +
                (physicianInput.physicalExaminationNotes || '')
        );
    }
    if (dataSources[DATA_SOURCE_DIAGNOSIS]) {
        const diagnoses = physicianInput.diagnoses
            .map((x) => `${x.code} -- ${x.codeBezeichnung}`)
            .join('\n');
        summarySections.push(
            `${t('diagnosis')}:\n${diagnoses}\n${physicianInput.diagnosisNotes || ''}`
        );
    }
    if (dataSources[DATA_SOURCE_THERAPY]) {
        summarySections.push(
            `${t('therapy')}:\n` + (physicianInput.treatment || '')
        );
    }
    if (dataSources[DATA_SOURCE_EPICRISIS]) {
        summarySections.push(
            `${t('epicrisis')}:\n` + (physicianInput.epicrisis || '')
        );
    }
    return summarySections.join('\n\n');
}

const DataSourcesCheckboxes = ({ dataSources, toggleDataSource }) => {
    const { t } = useTranslation();

    return (
        <div className="checkboxes-container">
            {[...Object.entries(dataSources)].map(
                ([name, checked]) => (
                    <span
                        key={name}
                        className="summary-checkbox-container"
                        onClick={() => toggleDataSource(name, checked)}
                    >
                        <input
                            type="checkbox"
                            onChange={() => {}}
                            checked={checked}
                        ></input>
                        <label>{t(name)}</label>
                    </span>
                )
                // (x) => <>{x} ; </>
            )}{' '}
        </div>
    );
};

function SummaryCardContents() {
    // Inherited Context.
    const {
        state: { physicianInput /*patientId*/ },
        // dispatch,
    } = useContext(PatientContext);
    const { t } = useTranslation();

    const patientData = usePatientData({ category: 'anamnesis' });
    const summaryRef = useRef();

    // Component State.
    const [dataSources, setDataSources] = useSessionStorage(
        'summaryDataSources',
        Object.fromEntries(DATA_SOURCES.map((dataSource) => [dataSource, true]))
    );

    const toggleDataSource = (name, checked) => {
        setDataSources({
            ...dataSources,
            [name]: !checked,
        });
    };

    const summary = dataSourcesToSummary(
        dataSources,
        physicianInput,
        patientData,
        t
    );

    return (
        <>
            <div className="heading">
                <span>{t('summary')}</span>
                <ZoomButton />
            </div>
            <DataSourcesCheckboxes
                dataSources={dataSources}
                toggleDataSource={toggleDataSource}
            />
            <hr className="divider" />
            <textarea
                ref={summaryRef}
                className="summary"
                value={summary}
                readOnly={true}
            />
            <CopyButton copyText={summary} />
        </>
    );
}

export function SummaryCard() {
    return <ModalRenderTarget render={() => <SummaryCardContents />} />;
}
