import { useContext } from 'react';
import LoadingIcon from '../../helpers/LoadingIcon.helpers';
import {
    FaMagnifyingGlassPlus,
    FaWandMagicSparkles,
    FaX,
} from 'react-icons/fa6';
import { useCopy } from './CopyIcon';
import { OutputCardContext } from './OutputCardContext';
import { useCloseModal, useInModal, useSummonModal } from './ModalContext';

export function ContextualLoadingIcon() {
    const { query } = useContext(OutputCardContext);

    return query.isLoading && <LoadingIcon />;
}

export function SuggestButton({ onClick }) {
    return (
        <span className="cursor-pointer" onClick={onClick}>
            <FaWandMagicSparkles />
        </span>
    );
}

export function ZoomButton() {
    const inModal = useInModal();
    const summonModal = useSummonModal();
    const closeModal = useCloseModal();

    const handleClick = () => {
        if (inModal) {
            closeModal();
        } else {
            summonModal();
        }
    };

    return (
        <span className="cursor-pointer" onClick={handleClick}>
            {inModal ? <FaX /> : <FaMagnifyingGlassPlus />}
        </span>
    );
}

export const OutputCardHeader = ({ title, loading, suggest, copyText }) => {
    const { copiedRecently, handleClickCopy } = useCopy({ copyText });

    let labelClassName = 'copy-icon-label';
    if (!copiedRecently) labelClassName += ' invisible';

    let copyIconClassName = 'copy-icon';
    if (!copyText) copyIconClassName += ' invisible';

    return (
        <div className="heading justify-content-space-between">
            <span className="flex align-items-center gap-10px">
                <span>{title}</span>
                {loading && <LoadingIcon />}
            </span>
            <span className="flex align-items-center gap-10px">
                <span className={labelClassName}>Copied!</span>
                {suggest && (
                    <SuggestButton
                        onClick={() => {
                            suggest();
                        }}
                    />
                )}
                <img
                    src="/copy-icon.svg"
                    className={copyIconClassName}
                    onClick={handleClickCopy}
                />
                <ZoomButton />
            </span>
        </div>
    );
};

// Like OutputCardHeader, but pulls props from context.
export function ContextualOutputCardHeader(props) {
    const { title, query, generateSuggestion, data, category } =
        useContext(OutputCardContext);

    return (
        <OutputCardHeader
            title={title}
            loading={query.isLoading}
            suggest={generateSuggestion}
            copyText={data}
            category={category}
            {...props}
        />
    );
}

// Like SuggestButton, but pulls props from context.
export function ContextualSuggestButton() {
    const { generateSuggestion } = useContext(OutputCardContext);

    return <SuggestButton onClick={generateSuggestion} />;
}
