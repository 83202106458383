import React from 'react';
import './EndScreen.css';
import logo from '../../../assets/logoFull.png';
import { useTranslation } from 'react-i18next';

const EndScreen = ({ handlePageChange }) => {
    const { t } = useTranslation();

    return (
        <div
            className="end-of-form-screen"
            onTouchEnd={() => handlePageChange('StartScreen')}
            onClick={() => handlePageChange('StartScreen')}
        >
            <div className="end-screen-logo">
                <img src={logo} alt="Logo" />
            </div>
            <div className="end-screen-message">{t('thankYouForSharing')}</div>
        </div>
    );
};

export default EndScreen;
