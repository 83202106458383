import React from 'react';
import { AiOutlineLoading3Quarters } from 'react-icons/ai';
import './LoadingIcon.helpers.css';

const LoadingIcon = ({ className = '', iconClassName }) => {
    return (
        <div className={`icon-container ${className}`}>
            <AiOutlineLoading3Quarters
                className={iconClassName ?? 'rotating-icon'}
            />
        </div>
    );
};

export default LoadingIcon;
