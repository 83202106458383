import React, { useContext, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import './OutputCard.css';
import { PatientContext } from './PatientContext';
import { ContextualOutputCardHeader } from './OutputCardHeader';
import { useApiQuery } from '../../helpers/useApiQuery';
import { markdownToText } from '../../helpers/markdownToText';
import { OutputCardContext } from './OutputCardContext';
import { ModalRenderTarget } from './ModalContext';
import { throwsNamedError } from '../../helpers/withErrorAnnotation';
import { useSwapEffect } from '../../helpers/useSwapEffect';
import { useRefreshSuggestionCallback } from '../../helpers/RefreshSuggestions';

function patientSelected(patientId) {
    return (
        patientId !== null && patientId !== undefined && patientId.trim() !== ''
    );
}

export function OutputCardBody({ className }) {
    const { patientId, data, onChange, suggestion } =
        useContext(OutputCardContext);

    if (!patientSelected(patientId)) {
        return <div>No patient selected</div>;
    } else {
        return (
            <div className={`scroll-container ${className}`}>
                <textarea
                    value={data || ''}
                    onChange={onChange}
                    readOnly={Boolean(suggestion)}
                    placeholder="..."
                />
            </div>
        );
    }
}

export function OutputCardFooter() {
    const { suggestion, setSuggestion, setData } =
        useContext(OutputCardContext);

    const handleClickAccept = () => {
        setData(suggestion);
        setSuggestion(undefined);
    };

    const handleClickDismiss = () => {
        setSuggestion(undefined);
    };

    return (
        suggestion && (
            <div>
                <button onClick={handleClickAccept}>Accept</button>
                <button onClick={handleClickDismiss}>Dismiss</button>
            </div>
        )
    );
}

// Map of (category->struct) which describes how to handle dispatches,
// server reads, and server writes for various fields.
const CATEGORY_KEYS = {
    treatment: {
        dispatchType: 'SET_TREATMENT',
        field: 'treatment',
        url: (patientId) => `/patients/${patientId}/treatment`,
        message: (data) => data.treatment.message,
    },
    diagnosis: {
        dispatchType: 'SET_DIAGNOSIS_NOTES',
        field: 'diagnosisNotes',
        url: (patientId) => `/patients/${patientId}/diagnosis`,
        message: (data) => data.diagnosis.message,
    },
    epicrisis: {
        dispatchType: 'SET_EPICRISIS',
        field: 'epicrisis',
        url: (patientId) => `/patients/${patientId}/epicrisis`,
        message: (data) => data.epicrisis.message,
    },
    anamnesis: {
        dispatchType: 'SET_ANAMNESIS',
        field: 'anamnesis',
        url: (patientId) => `/patients/${patientId}/anamnesis`,
        message: (data) => data.anamnesis.message,
    },
    physical_examination: {
        dispatchType: 'SET_PHYSICAL_EXAMINATION_NOTES',
        field: 'physicalExaminationNotes',
        url: (patientId) => `patients/${patientId}/physical-examination/`,
        message: (data) => data.physicalExaminationNotes.message,
    },
};

// Parent component that provides context used by header,footer,body.
// Useful for building alternate views of an OutputCard.
export function OutputCardProvider({ children, title, category, ...props }) {
    const { state, dispatch } = useContext(PatientContext);
    const { i18n } = useTranslation();
    const [suggestion, setSuggestionState] = useState(undefined);
    const prevSuggestionRef = useRef();
    const query = useApiQuery();

    const setSuggestion = (value) => {
        // If there is a suggestion currently,
        // store it as the previous suggestion.
        if (suggestion) {
            prevSuggestionRef.current = suggestion;
        }

        setSuggestionState(value);
    };

    const userText = state.physicianInput[CATEGORY_KEYS[category].field];

    // Every time the language changes, submit a query to translate the field.
    useSwapEffect({
        invariant: userText,
        variant: i18n.language,
        callback: () => {
            query.cancel();
            setSuggestion(null);
            query.submit({
                method: 'POST',
                path: '/translate',
                body: {
                    toLanguage: i18n.language,
                    userText: userText,
                },
                callback: (data) => {
                    if (data?.translated) {
                        setSuggestion(data.translation);
                    }
                },
            });
        },
    });

    const { patientId } = state;

    const { dispatchType, field } =
        CATEGORY_KEYS[category] ?? CATEGORY_KEYS.treatment;

    const setData = (value) => {
        dispatch({
            type: dispatchType,
            payload: value,
        });
    };

    const handleChange = (ev) => {
        dispatch({
            type: dispatchType,
            payload: ev.target.value,
        });
    };

    const generateSuggestion = (options = {}) => {
        const url = CATEGORY_KEYS[category].url(patientId);

        query.submit({
            method: 'POST',
            path: url,
            body: {
                userText: state.physicianInput[field],
                // Include the latest physician input state
                // such that user text is taken into account
                // even if it hasn't been saved yet.
                physicianInput: state.physicianInput,
                ...options,
            },
            callback: throwsNamedError(`error.${category}`, (data) => {
                if (!data) return;

                // For generate requests, we should actually ignore cache hits.
                // We only want the suggestion if it is updated.
                if (options.cacheKey === 'generate' && data.cacheHit) {
                    return;
                }
                const message = CATEGORY_KEYS[category].message(data);

                const suggestion = markdownToText(message);
                if (suggestion !== prevSuggestionRef.current) {
                    setSuggestion(suggestion);
                }
            }),
        });
    };

    useRefreshSuggestionCallback(({ updatedFields }) => {
        // If the field corresponding to the category of this output card
        // was not updated, then we should try refreshing the suggestion.
        if (updatedFields[CATEGORY_KEYS[category].field] === false) {
            generateSuggestion({ cacheKey: 'generate' });
        }
    });

    const displayData = suggestion || state.physicianInput[field];

    return (
        <OutputCardContext.Provider
            value={{
                title,
                query,
                data: displayData,
                setData,
                patientId,
                onChange: handleChange,
                suggestion,
                setSuggestion,
                generateSuggestion,
                category,
                ...props, // Any extra props go straight into context.
            }}
        >
            {children}
        </OutputCardContext.Provider>
    );
}

// Default arrangement. You can always build your own output card
// by using the <OutputCardProvider> component and header/body/footer
// components directly.
export const OutputCard = ({ title, category, showHeader }) => {
    return (
        <ModalRenderTarget
            render={() => (
                <OutputCardProvider
                    title={title}
                    category={category}
                    showHeader={showHeader}
                >
                    <div className="output-card-container">
                        <div
                            className={
                                showHeader
                                    ? 'output-card'
                                    : 'output-card-no-header'
                            }
                        >
                            {showHeader && <ContextualOutputCardHeader />}

                            <OutputCardBody />

                            <OutputCardFooter />
                        </div>
                    </div>
                </OutputCardProvider>
            )}
        />
    );
};
