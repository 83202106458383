import './IntakeForm.css';
import { useState } from 'react';

import StartScreen from './components/StartScreen';
import FixedForm from './components/FixedForm';
import DataProtectionAgreement from './components/DataProtectionAgreement';
import ChatScreen from './components/ChatScreen';
import EndScreen from './components/EndScreen';

function IntakeForm() {
    const [page, setPage] = useState('StartScreen');
    const [token, setToken] = useState('');

    const renderContent = () => {
        switch (page) {
            case 'StartScreen':
                return <StartScreen handlePageChange={setPage} />;
            case 'FixedForm':
                return (
                    <FixedForm
                        handlePageChange={setPage}
                        handleToken={setToken}
                    />
                );
            case 'DataProtectionAgreement':
                return <DataProtectionAgreement handlePageChange={setPage} />;
            case 'ChatScreen':
                return <ChatScreen token={token} handlePageChange={setPage} />;
            case 'EndOfForm':
                return <EndScreen handlePageChange={setPage} />;
            default:
                return <StartScreen />;
        }
    };

    return (
        <div className="IntakeForm">
            <div className="content">{renderContent()}</div>
        </div>
    );
}

export default IntakeForm;
