import React, {
    useState,
    useMemo,
    useEffect,
    useContext,
    createContext,
} from 'react';
import './SearchBar.css';
import { FaSearch, FaPlus } from 'react-icons/fa';
import { debounce } from '../../helpers/debounce';
import { PatientContext } from '../Dashboard/PatientContext';
import { FaCheck, FaWandSparkles, FaX } from 'react-icons/fa6';
import { useInModal } from '../Dashboard/ModalContext';
import { useTranslation } from 'react-i18next';
import { IcdDiagnosis } from './Diagnosis/IcdDiagnosis';

/// Parse a query into [code, codeBezeichnung]
function parseQuery(query) {
    const spaceIndex = query.indexOf(' ');

    if (spaceIndex !== -1) {
        return [
            query.substring(0, spaceIndex),
            query.substring(spaceIndex + 1),
        ];
    }

    return [query, null];
}

/// Should the results for a given query be displayed?
function shouldShowResults(query) {
    if (query === '') {
        return false;
    }

    // Don't show results if query has a code
    const regex = /[A-Z]\d{2}\./;
    return !regex.test(query);
}

// Hook to handle all the details of fetching query results.
function useQueryResults(query, availableDiagnosesQuery) {
    const [queryResults, setQueryResults] = useState({});

    // We memoize this function so that it plays nice with debounce.
    // Because we are memoizing, this func shouldn't touch state.
    // Instead, inputs and outputs should be explicit.
    const submit = availableDiagnosesQuery.submit;
    const fetchQueryResults = useMemo(
        () =>
            debounce((queryParam, callback) => {
                submit({
                    method: 'GET',
                    path: `/icd/search?query=${queryParam}`,
                    callback,
                });
            }, 200), // 200ms delay
        [submit]
    );

    // Now this little effect down here is NOT memoized,
    // so it has access to all of the usual state.
    useEffect(() => {
        // We pass in a callback instead of using `.then()`
        // to indicate that fetchQueryResults may or may not
        // actually call this.
        fetchQueryResults(query, (data) => {
            if (data) {
                setQueryResults((q) =>
                    Object.keys(q).length > 100
                        ? { [query]: data }
                        : { ...q, [query]: data }
                );
            }
        });
    }, [query, fetchQueryResults]);

    return queryResults;
}

function RemoveButton({ item, removeItem, dismiss }) {
    if (item.type === 'selected') {
        return <button onClick={removeItem}>-</button>;
    } else if (item.type === 'suggested') {
        return (
            <button onClick={dismiss}>
                <FaX color="red">no</FaX>
            </button>
        );
    }

    console.warn(`Unrecognized item type: ${item.type}`);
    // Since this component is used within a grid,
    // we should always return a DOM element
    // to preserve ordering.
    return <span></span>;
}

// Where item is a diagnosis object with "why" and "whynot" keys.
function ReasonsWhyOrWhyNot({ item }) {
    const { why, whynot } = item;
    const { t } = useTranslation();

    return (
        <>
            {why.map((reason, idx) => (
                <div key={idx}>
                    <b>{t('why')}</b> {reason}
                </div>
            ))}
            {whynot.map((reason, idx) => (
                <div key={idx}>
                    <b>{t('whynot')}</b> {reason}
                </div>
            ))}
        </>
    );
}

function SelectedItem({ item, removeItem, dismiss, apply }) {
    const displayedText =
        item.type === 'suggested'
            ? `[SUGGESTED] ${item.codeBezeichnung}`
            : item.codeBezeichnung;

    const isInModal = useInModal();

    // Render as fragment such that components
    // are direct children of parent grid.
    return (
        <>
            <b>{item.code}</b>
            <div>
                <div>
                    <span>{displayedText}</span>
                </div>
                {isInModal && item.type === 'suggested' && (
                    <ReasonsWhyOrWhyNot item={item} />
                )}
            </div>
            <RemoveButton
                item={item}
                removeItem={removeItem}
                dismiss={dismiss}
            />
            {item.type === 'suggested' ? (
                <button onClick={apply}>
                    <FaCheck>yes</FaCheck>
                </button>
            ) : (
                <span />
            )}
        </>
    );
}

const SearchBarContext = createContext();

export function SearchBarProvider({
    children,
    onDiagnosis,
    selectedItems,
    suggestedDiagnosesQuery,
    availableDiagnosesQuery,
}) {
    const { state, dispatch } = useContext(PatientContext);
    const patientId = state.patientId;

    // State to hold the search query.
    const [query, setQuery] = useState('');
    // queryResults is a map of query to result.
    const queryResults = useQueryResults(query, availableDiagnosesQuery);

    const [suggestedItems, setSuggestedItems] = useState([]);

    // Computed property.
    const displayResults =
        (shouldShowResults(query) && queryResults[query]) || [];

    // Handler for input change (query search bar).
    const handleInputChange = (event) => {
        const value = event.target.value;
        setQuery(value);
    };

    // Handler for form submission
    const handleSubmit = (event) => {
        event.preventDefault();

        // If query is non-empty, then add selected code.
        if (query) {
            // Find the selected item based on the query
            const [code, codeBezeichnung] = parseQuery(query);
            if (code) {
                setQuery(''); // Clear the search bar
                onDiagnosis({ code, codeBezeichnung });
            }
            // If query is empty, then auto-suggest codes.
        } else {
            suggestedDiagnosesQuery.submit({
                method: 'GET',
                path: `/patients/${patientId}/suggested-diagnoses`,
                callback: (suggestedDiagnoses) => {
                    const { codes } = suggestedDiagnoses;
                    // Only include suggestions
                    // that are not already selected.
                    const selectedCodes = selectedItems.map((x) => x.code);
                    setSuggestedItems(
                        codes.filter((x) => !selectedCodes.includes(x.code))
                    );
                },
            });
        }
    };

    // Handler for clicking on a dropdown item
    const handleDropdownClick = (item) => {
        setQuery(`${item.code} ${item.codeBezeichnung}`);
    };

    const removeItem = (item) => () => {
        dispatch({
            type: 'REMOVE_DIAGNOSIS',
            payload: item.code,
        });
    };

    const dismissSuggestion = (item) => () => {
        setSuggestedItems(suggestedItems.filter((x) => x.code !== item.code));
    };

    const applySuggestion = (item) => () => {
        dispatch({
            type: 'APPEND_DIAGNOSIS',
            payload: { code: item.code, codeBezeichnung: item.codeBezeichnung },
        });

        setSuggestedItems(suggestedItems.filter((x) => x.code !== item.code));
    };

    const changeItem = (oldCode) => (newCode) => {
        dispatch({
            type: 'UPDATE_DIAGNOSIS',
            payload: { oldCode, newCode },
        });
    };

    return (
        <SearchBarContext.Provider
            value={{
                query,
                handleInputChange,
                displayResults,
                handleSubmit,
                removeItem,
                changeItem,
                dismissSuggestion,
                applySuggestion,
                selectedItems,
                suggestedItems,
                handleDropdownClick,
            }}
        >
            {children}
        </SearchBarContext.Provider>
    );
}

export function SearchBarHeader() {
    const { query, handleInputChange, handleSubmit } =
        useContext(SearchBarContext);

    return (
        <form onSubmit={handleSubmit} className="search-form">
            <FaSearch className="search-icon" />
            <input
                type="text"
                placeholder="Search..."
                value={query}
                onChange={handleInputChange}
                autoComplete="off"
            />
            <button type="submit">
                {query ? <FaPlus /> : <FaWandSparkles />}
            </button>
        </form>
    );
}

function SelectedItems() {
    const {
        removeItem,
        dismissSuggestion,
        applySuggestion,
        selectedItems,
        suggestedItems,
        changeItem,
    } = useContext(SearchBarContext);

    const isInModal = useInModal();

    const allItems = [
        ...selectedItems.map((item) => ({ ...item, type: 'selected' })),
        ...suggestedItems.map((item) => ({ ...item, type: 'suggested' })),
    ];

    if (isInModal) {
        return (
            <>
                {allItems.map((item) => (
                    <IcdDiagnosis
                        key={item.code}
                        code={item.code}
                        onRemove={removeItem(item)}
                        onChangeCode={changeItem(item.code)}
                        type={item.type}
                        onDismiss={dismissSuggestion(item)}
                        onApply={applySuggestion(item)}
                    />
                ))}
            </>
        );
    } else {
        return (
            <div className="suggested-items">
                {allItems.map((item) => (
                    <SelectedItem
                        key={item.code}
                        item={item}
                        removeItem={removeItem(item)}
                        dismiss={dismissSuggestion(item)}
                        apply={applySuggestion(item)}
                    />
                ))}
            </div>
        );
    }
}

export function SearchBarBody() {
    const { displayResults, handleDropdownClick } =
        useContext(SearchBarContext);

    return (
        <>
            {/* Dropdown for filtered results */}
            {displayResults.length > 0 && (
                <ul className="search-dropdown">
                    {displayResults.map((item, index) => (
                        <li
                            key={index}
                            onClick={() => handleDropdownClick(item)}
                        >
                            {item.code} {item.codeBezeichnung}
                        </li>
                    ))}
                </ul>
            )}
            <SelectedItems />
        </>
    );
}

function SearchBar({
    onDiagnosis,
    selectedItems,
    suggestedDiagnosesQuery,
    availableDiagnosesQuery,
}) {
    const props = {
        onDiagnosis,
        selectedItems,
        suggestedDiagnosesQuery,
        availableDiagnosesQuery,
    };

    return (
        <SearchBarProvider {...props}>
            <div className="search-bar-container">
                <SearchBarHeader />
                <SearchBarBody />
            </div>
        </SearchBarProvider>
    );
}

export default SearchBar;
