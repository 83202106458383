// Object to hold values that need to be reactive,
// but might be convenient to update outside of react.

import { useEffect, useState } from 'react';

export class Observable {
    constructor(initialValue) {
        this._observers = [];
        this._value = initialValue;
    }

    addListener(listener) {
        this._observers.push(listener);
    }

    removeListener(listener) {
        this._observers = this._observers.filter((x) => x !== listener);
    }

    get value() {
        return this._value;
    }

    update(value) {
        this._value = value;
        for (const observer of this._observers) {
            observer(value);
        }
    }
}

// Use an observable value from react. Returns a read-only value.
export function useObservable(observable) {
    const [value, setValue] = useState(observable.value);

    useEffect(() => {
        observable.addListener(setValue);
        return () => {
            observable.removeListener(setValue);
        };
    }, [observable]);

    return value;
}
