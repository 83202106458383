import './Website.css';
import React from 'react';
import WebsiteHeader from './components/WebsiteHeader/WebsiteHeader';
import '../../global.css';

import HeroSection from './components/HeroSection';
import ProblemSolutionSection from './components/ProblemSolutionSection';
import BenefitsSection from './components/BenefitsSection';
import PilotProgramSection from './components/PilotProgramSection';
import TestimonialsSection from './components/TestimonialsSection';
import FeaturesSection from './components/FeaturesSection';
import FAQSection from './components/FAQSection';
import FooterSection from './components/FooterSection';

const Website = () => {
    return (
        <div className="website-top-level">
            <div className="scroll-container">
                <WebsiteHeader
                    showPatientSelector={false}
                    showAccountDetails={false}
                />
                <div className="sections-container">
                    <HeroSection />
                    <div className="background-gradient">
                        <ProblemSolutionSection />
                        <BenefitsSection />
                        <PilotProgramSection />
                        <TestimonialsSection />
                        <FeaturesSection />
                        <FAQSection />
                    </div>
                    <FooterSection />
                </div>
            </div>
        </div>
    );
};

export default Website;
