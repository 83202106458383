import { useCodeDetails } from './IcdCodeDetailsCache';
import { IcdDisplay } from './IcdDisplay';

// Transform an ICD-10 code into a breadcrumbs path,
// where each element of the path is editable.
// string -> string[]
function pathFromCode(code) {
    const [before, after] = code.split('.');
    if (!after) return [code];
    return [`${before}.`, ...after];
}

// Transform an <ICDDiagnosis> `onChangeCode` handler into
// a <Breadcrumbs> `onChangePath` handler.
const onChangeCodeToOnChangePath =
    ({ onChangeCode }) =>
    (newPath) => {
        // Technically this transform is a no-op (for now).
        // Weird oddity but oh well.
        onChangeCode(newPath);
    };

// Transform the value and onChange handler for the ICD-10 code
// into the corresponding items for the  breadcrumbs path.
function usePathController({ code, onChangeCode }) {
    const path = pathFromCode(code);

    const onChangePath = onChangeCodeToOnChangePath({ onChangeCode });

    return {
        path,
        onChangePath,
    };
}

// Controlled component for ICD display.
// Potential paths are considered as a separate prop
// from code details because they can be fetched in the background.
// In constrast, code description and notes should be available immediately.
export function IcdDiagnosis({
    code,
    onChangeCode,
    onRemove,
    type,
    onApply,
    onDismiss,
}) {
    const pathController = usePathController({ code, onChangeCode });
    const codeDetails = useCodeDetails(code);

    let fullName = codeDetails?.fullName ?? '';
    if (type === 'suggested') fullName = `[SUGGESTED] ${fullName}`;

    return (
        <IcdDisplay
            path={pathController.path}
            onChangePath={pathController.onChangePath}
            isLoading={!codeDetails?.potentialPaths}
            potentialPaths={type !== 'suggested' && codeDetails?.potentialPaths}
            fullName={fullName}
            description={codeDetails?.description}
            notes={codeDetails?.notes ?? []}
            onRemove={type !== 'suggested' && onRemove}
            onApply={type === 'suggested' && onApply}
            onDismiss={type === 'suggested' && onDismiss}
        />
    );
}
