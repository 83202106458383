import React from 'react';
import './InputField.css';

const InputField = ({ value, placeholder, onChange }) => {
    console.log(value);
    console.log(onChange);
    return (
        <div className="input-wrapper">
            <input
                type="text"
                placeholder={placeholder}
                value={value}
                onChange={(e) => onChange(e.target.value)}
            />
            <span className="underline"></span>
        </div>
    );
};

export default InputField;
