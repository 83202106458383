import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { PatientContext } from '../Dashboard/PatientContext';

import './LanguageSwitcher.css';

export function LanguageSwitcher() {
    const { i18n } = useTranslation();
    const patientContext = useContext(PatientContext);

    const language = i18n.resolvedLanguage;

    const enClassName = language === 'en' ? 'active' : '';
    const deClassName = language === 'de' ? 'active' : '';

    const handleLanguageChange = (newLanguage) => {
        if (patientContext?.state?.physicianInput?.dirty) {
            if (!window.confirm('Discard changes to the current patient?')) {
                return;
            }
        }
        i18n.changeLanguage(newLanguage);
    };

    return (
        <span className="language-switcher">
            <button
                className={enClassName}
                onClick={() => handleLanguageChange('en')}
            >
                EN
            </button>
            |
            <button
                className={deClassName}
                onClick={() => handleLanguageChange('de')}
            >
                DE
            </button>
        </span>
    );
}
