import React, { useState } from 'react';
import './SecretaryDashboard.css';
import axios from 'axios';
import { serverUrl } from '../../config';

const SecretaryDashboard = () => {
    const [formData, setFormData] = useState({
        name: '',
        insuranceCardNumber: '',
        chronicIllnesses: '',
        surgeries: '',
        currentMedications: '',
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        console.log('Form Data:', formData);
        //
        //TODO FUNCTION FOR SUBMITTING DATA
        //
        const result = await axios.post(
            `${serverUrl}/secretary/intake-status`,
            formData
        );
        console.log('Result:', result);
    };

    return (
        <div className="sec-dashboard">
            <div className="secretary-intake-column">
                <h2>Patient Information Form</h2>
                <form onSubmit={handleSubmit}>
                    <div className="patient-name">
                        <label>
                            Name:
                            <br />
                            <input
                                type="text"
                                name="name"
                                placeholder="Enter patient name"
                                value={formData.name}
                                onChange={handleChange}
                                required
                            />
                        </label>
                    </div>
                    <div className="patient-insurance-card-number">
                        <label>
                            Insurance Card Number:
                            <br />
                            <input
                                type="text"
                                name="insuranceCardNumber"
                                placeholder="Enter insurance card number"
                                value={formData.insuranceCardNumber}
                                onChange={handleChange}
                                required
                            />
                        </label>
                    </div>
                    <div className="patient-chronic-illnesses">
                        <label>
                            Chronic Illnesses:
                            <br />
                            <textarea
                                name="chronicIllnesses"
                                placeholder="Enter known chronic illnesses"
                                value={formData.chronicIllnesses}
                                onChange={handleChange}
                            />
                        </label>
                    </div>
                    <div className="patient-important-surgeries">
                        <label>
                            Important Surgeries:
                            <br />
                            <textarea
                                name="surgeries"
                                placeholder="Enter known surgery history"
                                value={formData.surgeries}
                                onChange={handleChange}
                            />
                        </label>
                    </div>
                    <div className="patient-current-medications">
                        <label>
                            Current Medications:
                            <br />
                            <textarea
                                name="currentMedications"
                                placeholder="Enter current medications"
                                value={formData.currentMedications}
                                onChange={handleChange}
                            />
                        </label>
                    </div>
                    <div className="submit-button">
                        <button type="submit">Generate</button>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default SecretaryDashboard;
