// src/Header/components/SaveButton.jsx
import React, { useContext } from 'react';
import './SaveButton.css';
import { PatientContext } from '../../Dashboard/PatientContext';
import { useApiQuery } from '../../../helpers/useApiQuery';
import LoadingIcon from '../../../helpers/LoadingIcon.helpers';
import { useTranslation } from 'react-i18next';
import { useRefreshSuggestionTrigger } from '../../../helpers/RefreshSuggestions';

const SaveButton = () => {
    const { state, dispatch } = useContext(PatientContext);
    const saveQuery = useApiQuery();
    const refreshSuggestionTrigger = useRefreshSuggestionTrigger();
    const { t } = useTranslation();

    const savePhysicianInput = () => {
        saveQuery.submit({
            method: 'POST',
            path: '/patients/physician-input/',
            body: {
                patientId: state.patientId,
                physicianInput: state.physicianInput,
            },
            callback: (data) => {
                if (!data) {
                    return;
                }

                dispatch({ type: 'MARK_PHYSICIAN_INPUT_CLEAN' });
                const { updatedFields } = data;
                refreshSuggestionTrigger({ updatedFields });
            },
        });
    };

    if (!state.patientId) {
        return null;
    }

    return (
        <div className="save-button-container">
            <button onClick={savePhysicianInput}>{t('save')}</button>
            {state.physicianInput.dirty && (
                <div className="unsaved-text">{t('unsavedChanges')}</div>
            )}
            {saveQuery.isLoading && <LoadingIcon />}
        </div>
    );
};

export default SaveButton;
