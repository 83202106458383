import { marked } from 'marked';

function tokensToText(tokens) {
    const parts = [];

    for (const token of tokens) {
        if (token.tokens && token.tokens.length) {
            parts.push(tokensToText(token.tokens));
        } else if (token.type === 'text') {
            parts.push(token.text);
        } else if (token.type === 'list') {
            parts.push('\n');
            parts.push(tokensToText(token.items));
        } else {
            // debugger;
            console.warn(`Unexpected token type: ${token.type}`);
        }
    }

    return parts.join('');
}

function blockToText(block) {
    if (block.type === 'paragraph') {
        return tokensToText(block.tokens) + '\n';
    } else if (block.type === 'list') {
        return block.items
            .map((item) => tokensToText(item.tokens))
            .join('\n\n');
    } else if (block.type === 'space') {
        return '\n';
    } else {
        console.warn(`Unexpected block type: ${block.type}`);
    }
}

export function markdownToText(markdown) {
    const tokens = marked.lexer(markdown);
    const text = tokens.map(blockToText).join('');
    // Marked is quite agressive about escaping quotes.
    // https://github.com/markedjs/marked/issues/269
    // This is a bit annoying, but there aren't actually
    // that many characters that tend to get escaped.
    // So a find-and-replace is reasonable and easy enough.
    return text.replaceAll('&#39;', "'").trim();
}
