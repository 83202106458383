import React from 'react';
import './SummaryColumn.css';
import { FreeTextEditorCard } from './FreeTextEditor';
import { SummaryCard } from './SummaryCard';

// Component to hold entire column.
// Includes header, checkboxes, and summary.
export const SummaryColumn = () => {
    return (
        <div className="summary-column">
            <div className="card doq-summary-card">
                <SummaryCard />
            </div>
            <div className="card doq-billing-card">
                <FreeTextEditorCard />
            </div>
        </div>
    );
};
