// Global observable / reactive state for whether
// we have a connection to our server.

import { makeRESTRequest } from '../components/Dashboard/serverUtils';
import { throwNamedError } from '../components/Header/GlobalErrorDisplay';
import { debounceEager } from './debounce';
import { Observable, useObservable } from './Observable';

const STATUS_UNKNOWN = 'unknown';
const STATUS_DISCONNECTED = 'disconnected';
const STATUS_CONNECTED = 'connected';

export const serverStatus = new Observable(STATUS_UNKNOWN);

const POLL_MS = 10_000;

let currentlyPolling = false;
// If we connect to the server succesfully,
// then we should cancel any previous error message automatically.
let cancelHandle;

export const updateServerStatus = debounceEager(async () => {
    if (currentlyPolling) return;

    try {
        currentlyPolling = true;
        const response = await makeRESTRequest(
            'GET',
            // Route that requires auth.
            // So if our token expires, this should fail.
            '/patients'
        );
        if (!response) {
            throw new Error('null server response');
        }
        // If no error was raised,
        // then go ahead and update the status to disconnected.
        serverStatus.update(STATUS_CONNECTED);

        // If we were displaying a "Server disconnected" message,
        // cancel it.
        cancelHandle?.();
    } catch (error) {
        // If an error was raised, consider the server to be down.
        console.warn(error);
        serverStatus.update(STATUS_DISCONNECTED);

        cancelHandle = throwNamedError('error.network');
    } finally {
        // Unset the polling flag such that the next `updateServerStatus()`
        // call can go through.
        currentlyPolling = false;
    }
}, POLL_MS);

export function useServerStatus() {
    return useObservable(serverStatus);
}
