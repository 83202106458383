import React, { useState } from 'react';

export const PPB = () => {
    const [intakeFindings, setIntakeFindings] = useState('');

    const handleIntakeFindingsChange = (event) => {
        setIntakeFindings(event.target.value);
    };

    return (
        <div className="workspace">
            <div className="scroll-container">
                <div className="findings-card">
                    <div className="findings-header">
                        <div className="heading">
                            Erscheinungsbild, Kontaktverhalten
                        </div>
                        <button className="generate-button">
                            Normalbefund
                        </button>
                    </div>
                    <div className="findings-content"></div>
                </div>
                <div className="findings-card">
                    <div className="findings-header">
                        <div className="heading">Sprache</div>
                        <button className="generate-button">
                            Normalbefund
                        </button>
                    </div>
                    <div className="findings-content"></div>
                </div>
                <div className="findings-card">
                    <div className="findings-header">
                        <div className="heading">
                            Bewusstseinslage, Orientierung
                        </div>
                        <button className="generate-button">
                            Normalbefund
                        </button>
                    </div>
                    <div className="findings-content"></div>
                </div>
                <div className="findings-card">
                    <div className="findings-header">
                        <div className="heading">Mnestik</div>
                        <button className="generate-button">
                            Normalbefund
                        </button>
                    </div>
                    <div className="findings-content"></div>
                </div>
                <div className="findings-card">
                    <div className="findings-header">
                        <div className="heading">
                            Aufmerksamkeit, Konzentration
                        </div>
                        <button className="generate-button">
                            Normalbefund
                        </button>
                    </div>
                    <div className="findings-content"></div>
                </div>
                <div className="findings-card">
                    <div className="findings-header">
                        <div className="heading">Auffassung</div>
                        <button className="generate-button">
                            Normalbefund
                        </button>
                    </div>
                    <div className="findings-content"></div>
                </div>
                <div className="findings-card">
                    <div className="findings-header">
                        <div className="heading">Formales Denken</div>
                        <button className="generate-button">
                            Normalbefund
                        </button>
                    </div>
                    <div className="findings-content"></div>
                </div>
                <div className="findings-card">
                    <div className="findings-header">
                        <div className="heading">Wahrnehmung</div>
                        <button className="generate-button">
                            Normalbefund
                        </button>
                    </div>
                    <div className="findings-content"></div>
                </div>
                <div className="findings-card">
                    <div className="findings-header">
                        <div className="heading">Inhaltliches Denken</div>
                        <button className="generate-button">
                            Normalbefund
                        </button>
                    </div>
                    <div className="findings-content"></div>
                </div>
                <div className="findings-card">
                    <div className="findings-header">
                        <div className="heading">Ich-Störungen</div>
                        <button className="generate-button">
                            Normalbefund
                        </button>
                    </div>
                    <div className="findings-content"></div>
                </div>
                <div className="findings-card">
                    <div className="findings-header">
                        <div className="heading">Angste, Zwänge, Phobien</div>
                        <button className="generate-button">
                            Normalbefund
                        </button>
                    </div>
                    <div className="findings-content"></div>
                </div>
                <div className="findings-card">
                    <div className="findings-header">
                        <div className="heading">Stimmung & Affekt</div>
                        <button className="generate-button">
                            Normalbefund
                        </button>
                    </div>
                    <div className="findings-content"></div>
                </div>
                <div className="findings-card">
                    <div className="findings-header">
                        <div className="heading">Antrieb & Interesse</div>
                        <button className="generate-button">
                            Normalbefund
                        </button>
                    </div>
                    <div className="findings-content"></div>
                </div>
                <div className="findings-card">
                    <div className="findings-header">
                        <div className="heading">Psychomotorik</div>
                        <button className="generate-button">
                            Normalbefund
                        </button>
                    </div>
                    <div className="findings-content"></div>
                </div>
                <div className="findings-card">
                    <div className="findings-header">
                        <div className="heading">
                            Eigen-/Fremdgefährdung, Suizidalität
                        </div>
                        <button className="generate-button">
                            Normalbefund
                        </button>
                    </div>
                    <div className="findings-content"></div>
                </div>
                <div className="findings-card">
                    <div className="findings-header">
                        <div className="heading">Krankheitseinstellung</div>
                        <button className="generate-button">
                            Normalbefund
                        </button>
                    </div>
                    <div className="findings-content"></div>
                </div>
            </div>
            <div className="findings-summary-card">
                <div className="record-field">
                    <button className="generate-button">
                        Aufnahmebefund Generieren
                    </button>
                    <textarea
                        type="text"
                        placeholder="Internistischer Aufnahmebefund"
                        value={intakeFindings}
                        onChange={handleIntakeFindingsChange}
                    />
                </div>
            </div>
        </div>
    );
};
