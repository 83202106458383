import React from 'react';
import ImageCard from './ImageCard';
import TestimonialImage1 from '../../../assets/TestimonialImage1.png';
import TestimonialImage2 from '../../../assets/TestimonialImage2.png';
import TestimonialImage3 from '../../../assets/TestimonialImage3.png';
import Signature1 from '../../../assets/TestimonialSignature1.png';
import Signature2 from '../../../assets/TestimonialSignature2.png';
import Signature3 from '../../../assets/TestimonialSignature3.png';

const TestimonialsSection = () => {
    const Name1 = 'Dr. Emily Schneider, MD';
    const Name2 = 'Dr. Raj Patel, DO';
    const Name3 = 'Dr. Laura Nguyen, MD';

    return (
        <section className="section testimonials-section">
            <div className="heading-container">
                <h2>Doctors love Doq Copilot</h2>
            </div>
            <div className="text-row">
                <div className="text-column subheading">
                    <i>
                        &#34;As a family physician, time is my most precious
                        resource. Since integrating this software into my daily
                        practice, I’ve seen a remarkable reduction in the hours
                        spent on documentation. The intuitive interface allows
                        me to quickly input patient information, making my
                        visits more efficient. My patients appreciate the
                        increased time and attention I can now offer them during
                        consultations. I highly recommend this software to any
                        medical professional looking to enhance their
                        practice.&#34;
                    </i>
                    <div className="signature">
                        <img
                            src={Signature1}
                            alt="Doctor Signature"
                            className="signature-image"
                        />
                        <strong>{Name1}</strong>
                    </div>
                </div>
                <div className="image-column">
                    <ImageCard
                        image={TestimonialImage1}
                        heading={Name1}
                        subheading={'Family Medicine'}
                        description={'This is the description for card 1.'}
                    />
                </div>
            </div>
            <div className="text-row">
                <div className="image-column">
                    <ImageCard
                        image={TestimonialImage3}
                        heading={Name2}
                        subheading={'Internal Medicine'}
                        description={'This is the description for card 2.'}
                    />
                </div>
                <div className="text-column subheading">
                    <i>
                        &#34;Prior to using this software, I was overwhelmed by
                        the volume of paperwork required for patient
                        documentation. Now, I can complete my notes in a
                        fraction of the time, allowing me to focus more on
                        patient care. The customizable templates are a
                        game-changer, adapting to my specific workflow and
                        saving me from repetitive tasks. My team has also
                        reported increased productivity since we started using
                        it. This tool has truly transformed our approach to
                        patient documentation!&#34;
                    </i>
                    <div className="signature">
                        <img
                            src={Signature2}
                            alt="Doctor Signature"
                            className="signature-image"
                        />
                        <strong>{Name2}</strong>
                    </div>
                </div>
            </div>
            <div className="text-row">
                <div className="text-column subheading">
                    <i>
                        &#34;Documentation used to be a significant burden in my
                        pediatric practice, but this software has revolutionized
                        how I manage my notes. With its easy-to-use features, I
                        can efficiently record patient visits without
                        sacrificing quality. The time I save on documentation
                        now allows me to engage more meaningfully with my young
                        patients and their families. I’m grateful for this tool
                        that has streamlined my workflow and improved my
                        practice’s overall efficiency. I can’t imagine going
                        back to the old way of doing things!&#34;
                    </i>
                    <div className="signature">
                        <img
                            src={Signature3}
                            alt="Doctor Signature"
                            className="signature-image"
                        />
                        <strong>{Name3}</strong>
                    </div>
                </div>
                <div className="image-column">
                    <ImageCard
                        image={TestimonialImage2}
                        heading={Name3}
                        subheading={'Pediatrics'}
                        description={'This is the description for card 3.'}
                    />
                </div>
            </div>
        </section>
    );
};

export default TestimonialsSection;
