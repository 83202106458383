import { createContext, useContext, useEffect, useRef } from 'react';

const MakeProvider = (Context, name) => {
    const Component = ({ children }) => {
        const callbacks = useRef([]);

        return (
            <Context.Provider value={{ callbacks }}>
                {children}
            </Context.Provider>
        );
    };
    Component.displayName = name;
    return Component;
};

const makeUseCallback = (Context) => (callback) => {
    const ctx = useContext(Context);
    useEffect(() => {
        if (!ctx) {
            console.warn('no callbacks ref');
            return;
        }
        const { callbacks } = ctx;
        callbacks.current.push(callback);
        return () => {
            callbacks.current = callbacks.current.filter((x) => x !== callback);
        };
    }, [ctx, callback]);
};

const makeUseTrigger = (Context) => () => {
    const { callbacks } = useContext(Context);

    return (...args) => {
        for (const callback of callbacks.current) {
            callback(...args);
        }
    };
};

export function MakeCallbacks(name) {
    const Context = createContext();

    return {
        Provider: MakeProvider(Context, name),
        useCallback: makeUseCallback(Context),
        useTrigger: makeUseTrigger(Context),
    };
}
