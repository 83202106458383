import './PracticeDashboard.css';
import { AnamnesisColumn } from './AnamnesisColumn';
import { EpicrisisColumn } from './EpicrisisColumn';
import { SummaryColumn } from './SummaryColumn';
import { ModalDisplay, ModalProvider } from '../Dashboard/ModalContext';
import { DialogueProvider } from './Dialogue/DialogueContext';
import { useState } from 'react';

const PracticeDashboard = () => {
    const [broken, setBroken] = useState(false);

    if (broken) {
        throw new Error('Intentionally broke dashboard');
    }

    window.breakTheDashboard = () => {
        setBroken(true);
    };

    // Dialogue provider lives above modal provider such that
    // modal and non-modal dialogue cards can share context.

    return (
        <DialogueProvider>
            <ModalProvider>
                <div className="practice-dashboard">
                    <AnamnesisColumn />
                    <EpicrisisColumn />
                    <SummaryColumn />
                    <ModalDisplay />
                </div>
            </ModalProvider>
        </DialogueProvider>
    );
};

export default PracticeDashboard;
