class DoubleLinkedList {
    constructor() {
        this.head = undefined;
    }
    unshift(value) {
        this.head = {
            value,
            next: this.head,
            prev: undefined,
        };
        if (this.head.next) {
            this.head.next.prev = this.head;
        }
        return this.head;
    }
    remove(element) {
        // Element has a previous entry. Route it directly to the next.
        if (element.prev) {
            element.prev.next = element.next;
        }
        // Element has no previous entry.
        // Therefore, it must be the head of this list.
        else {
            this.head = element.next;
        }
    }

    *elements() {
        let current = this.head;
        while (current) {
            yield current;
            current = current.next;
        }
    }

    *values() {
        for (const element of this.elements()) {
            yield element.value;
        }
    }

    hasElements() {
        return Boolean(this.head);
    }
}

class SingleEventTargetImpl {
    constructor() {
        this._listeners = new DoubleLinkedList();
        this._handlerToElementMap = new Map();
    }

    addEventListener(handler) {
        const linkedListElement = this._listeners.unshift(handler);
        this._handlerToElementMap.set(handler, linkedListElement);
    }

    removeEventListener(handler) {
        const linkedListElement = this._handlerToElementMap.get(handler);
        this._listeners.remove(linkedListElement);
        this._handlerToElementMap.delete(handler);
    }

    dispatchEvent(event) {
        for (const listener of this._listeners.values()) {
            listener(event);
        }
    }

    hasListeners() {
        return this._listeners.hasElements();
    }
}

// Fairly naive implementation for the EventTarget interface.
// In its own file so we can optimize in the future if required.
export class EventTargetImpl {
    constructor() {
        this._singleEventTargets = new Map();
    }

    addEventListener(name, handler) {
        if (!this._singleEventTargets.has(name)) {
            this._singleEventTargets.set(name, new SingleEventTargetImpl());
        }
        this._singleEventTargets.get(name).addEventListener(handler);
    }

    removeEventListener(name, handler) {
        if (!this._singleEventTargets.has(name)) {
            console.warn(
                `Trying to remove listener for '${name}' event, ` +
                    `which does not exist`
            );
            return;
        }
        this._singleEventTargets.get(name).removeEventListener(handler);
        // If all listeners have been removed,
        // then there is no need to track the event target anymore.
        if (!this._singleEventTargets.get(name).hasListeners()) {
            this._singleEventTargets.delete(name);
        }
    }

    dispatchEvent(event) {
        const listenersForType = this._singleEventTargets.get(event.type);
        if (!listenersForType) return;

        listenersForType.dispatchEvent(event);
    }
}
