import { useContext, useEffect, useState } from 'react';
import { markdownToText } from './helpers/markdownToText';

import { PatientContext } from './components/Dashboard/PatientContext';
import { useRESTRequest } from './components/Dashboard/serverUtils';

// Temporary list of categories that have been migrated to the new routes. Once
// all categories have been migrated, this can be removed.
const UPDATED_CATEGORY_ROUTES = [
    'anamnesis',
    'epicrisis',
    'treatment',
    'diagnosis',
];

function patientSelected(patientId) {
    return (
        patientId !== null && patientId !== undefined && patientId.trim() !== ''
    );
}

function formatPatientIntakeFormResults(conversation) {
    if (!conversation) return;

    const slicedMessages = conversation.messages.slice(
        1,
        conversation.finished
            ? conversation.messages.length - 1
            : conversation.messages.length
    );

    const initialValue = '';
    const pifResultString = slicedMessages.reduce(
        (accumulator, currentValue) => {
            if (currentValue.role === 'system') {
                return (
                    accumulator + '**Q**: ' + currentValue.content + '  \n  '
                );
            } else if (currentValue.role === 'user') {
                return accumulator + '**A**: ' + currentValue.content + '\n\n';
            } else {
                console.error('Invalid role:', currentValue.role);
            }
        },
        initialValue
    );
    return pifResultString;
}

// Custom hook to grab extra patient data via GET request.
// Assumes access to a patient context.
export function usePatientData({ category }) {
    const {
        state: {
            patientId,
            physicianInput: { dirty: physicianInputDirty },
        },
    } = useContext(PatientContext);

    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(false);

    const makeRESTRequest = useRESTRequest();

    useEffect(() => {
        const route = UPDATED_CATEGORY_ROUTES.includes(category)
            ? `patients/${patientId}/${category}`
            : `/${category}/${patientId}`;

        const fetchData = () => {
            makeRESTRequest('GET', route).then((data) => {
                switch (category) {
                    case 'anamnesis':
                        setData(markdownToText(data?.anamnesis_message ?? ''));
                        break;
                    case 'conversations':
                        setData(formatPatientIntakeFormResults(data));
                        break;
                    case 'diagnosis':
                        setData(markdownToText(data?.diagnosis_message));
                        break;
                    case 'treatment':
                        setData(markdownToText(data?.treatment_message));
                        break;
                    case 'epicrisis':
                        setData(markdownToText(data?.epicrisis_message));
                        break;
                    default:
                        console.error('Invalid category:', category);
                }
                setLoading(false);
            });
        };

        if (patientSelected(patientId) && !physicianInputDirty) {
            setLoading(true);
            fetchData();
        }
    }, [
        patientId,
        category,
        physicianInputDirty,
        setLoading,
        setData,
        makeRESTRequest,
    ]);

    return {
        data,
        loading,
    };
}
