import React, { useState, useEffect } from 'react';
import './DataProtectionAgreement.css';
import Header from '../../Header';
import MarkdownRenderer from '../../../helpers/MarkdownRenderer.helpers';
import gdprAgreement from '../../../assets/gdprAgreement.txt';

const DataProtectionAgreement = ({ handlePageChange }) => {
    const [consent, setConsent] = useState(false);
    const [gdprAgreementContent, setGdprAgreementContent] = useState('');

    useEffect(() => {
        fetch(gdprAgreement)
            .then((response) => response.text())
            .then((text) => {
                setGdprAgreementContent(text);
            });
    }, []);

    const handleConsentSubmit = () => {
        if (consent) {
            handlePageChange('ChatScreen');
        }
    };

    const handleConsentChange = (e) => {
        setConsent(e.target.checked);
    };

    return (
        <div className="data-protection-page">
            <Header showPatientSelector={false} showAccountDetails={false} />
            <div className="data-protection-container">
                <div className="heading">Data Protection Policy</div>
                <div className="data-protection-content">
                    <MarkdownRenderer
                        markdownText={gdprAgreementContent}
                    ></MarkdownRenderer>
                </div>
                <div className="consent-container">
                    <div className="checkbox-container">
                        <input
                            type="checkbox"
                            checked={consent}
                            onChange={handleConsentChange}
                        ></input>
                        <label> I agree to the terms and conditions</label>
                    </div>
                    <button
                        className="continue-button"
                        onClick={() => handleConsentSubmit()}
                    >
                        Continue
                    </button>
                </div>
            </div>
        </div>
    );
};

export default DataProtectionAgreement;
