// Higher-order component to wrap a component up in a provider.
// Turns a 5-liner into a 1-liner and reduces need for components
// with names like <InnerThing> or <ThingContents>.
export const withProvider = (Component, Provider) => {
    const ProviderWrapper = (props) => (
        <Provider>
            <Component {...props} />
        </Provider>
    );
    return ProviderWrapper;
};
