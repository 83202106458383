import {
    createContext,
    useCallback,
    useContext,
    useRef,
    useState,
} from 'react';
import { FaArrowRight } from 'react-icons/fa';
import { useClickAway } from '../../../helpers/useClickAway';

import './Breadcrumbs.css';

const Context = createContext();

// Dropdown item for single potential path part.
// Event handler binds all the way up to <Breadcrumbs> via context.
function PotentialPathPartDropdownItem({
    potentialPathPart,
    color,
    selected,
    onSelect,
}) {
    const { onChangePath } = useContext(Context);

    const handleClick = () => {
        onChangePath(potentialPathPart.name);
    };

    const handleMouseEnter = () => {
        onSelect();
    };

    const handlers = {
        onClick: handleClick,
        onMouseEnter: handleMouseEnter,
    };

    return (
        <>
            <span className={selected ? '' : 'invisible'} {...handlers}>
                <FaArrowRight />
            </span>
            <span {...handlers} className={`item ${color}`}>
                {potentialPathPart.name}
            </span>
            <span {...handlers} className={`item ${color} fullname`}>
                {potentialPathPart.fullName}
            </span>
        </>
    );
}

// Dropdown applicable to specific component of path,
// containing a range of potential path parts
// which can be slotted into this path component.
function Dropdown({ domRef, color, potentialPathParts, pathPartIdx }) {
    const [selectedIdx, setSelectedIdx] = useState(undefined);

    const handleSelect = (idx) => () => {
        setSelectedIdx(idx);
    };

    return (
        <div ref={domRef} className={`dropdown ${color}`}>
            {potentialPathParts.map(
                (potentialPathPart, potentialPathPartIdx) => (
                    <PotentialPathPartDropdownItem
                        key={potentialPathPart.name}
                        potentialPathPart={potentialPathPart}
                        color={color}
                        pathPartIdx={pathPartIdx}
                        selected={potentialPathPartIdx === selectedIdx}
                        onSelect={handleSelect(potentialPathPartIdx)}
                    />
                )
            )}
        </div>
    );
}

function Diagonal({ color, final }) {
    return (
        <svg
            className={`diagonal-svg ${color} ${final ? 'final' : ''}`}
            viewBox="0 0 50 100"
        >
            <polygon points="0,100 0,0 50,50" />
        </svg>
    );
}

// Breadcrumb part including possibly expanded dropdown.
function BreadcrumbPart({
    pathPart,
    pathPartIdx,
    color,
    final,
    expanded,
    onExpand,
    potentialPathParts,
}) {
    const dropdownRef = useRef();

    const canExpand = potentialPathParts?.length > 0;

    const handleClick = (ev) => {
        const eventIsInDropdown = dropdownRef.current?.contains?.(ev.target);
        if (canExpand && !eventIsInDropdown) {
            onExpand();
        }
    };

    const disabled = !canExpand;

    return (
        <>
            <span
                onClick={handleClick}
                className={`part ${color} ${disabled ? 'disabled' : ''}`}
            >
                <span>{pathPart}</span>
                {expanded && (
                    <Dropdown
                        domRef={dropdownRef}
                        potentialPathParts={potentialPathParts}
                        color={color}
                        pathPartIdx={pathPartIdx}
                    />
                )}
            </span>
            {/* <span className={`diagonal ${color} ${final ? 'final' : ''}`} /> */}
            <Diagonal color={color} final={final} />
        </>
    );
}

// The core breadcrumbs component. Displays a path (list of strings),
// and makes potential paths available through a dropdown menu
// on each path element.
// Uses internal state to track which path element is currently expanded,
// if any.
export function Breadcrumbs({ path, potentialPaths, onChangePath }) {
    const [expandedPathIdx, setExpandedPathIdx] = useState(undefined);
    const ref = useRef();

    // When the user clicks anywhere outside this element,
    // un-expand it.
    useClickAway(
        ref,
        useCallback(() => {
            setExpandedPathIdx(undefined);
        }, [setExpandedPathIdx])
    );

    const handleExpand = (pathIdx) => () => {
        if (expandedPathIdx === pathIdx) {
            setExpandedPathIdx(undefined);
        } else {
            setExpandedPathIdx(pathIdx);
        }
    };

    // Wrap the `onChangePath()` we expose to breadcrumb parts
    // to automatically un-expand the dropdown when selecting a new path.
    const handleChangePath = (newPath) => {
        onChangePath(newPath);
        setExpandedPathIdx(undefined);
    };

    potentialPaths = potentialPaths || path.map(() => []);

    return (
        <Context.Provider
            value={{
                path,
                onChangePath: handleChangePath,
            }}
        >
            <span ref={ref} className="breadcrumbs">
                {path.map((pathPart, pathPartIdx) => (
                    <BreadcrumbPart
                        key={pathPartIdx}
                        pathPart={pathPart}
                        pathPartIdx={pathPartIdx}
                        color={pathPartIdx % 2 === 0 ? 'light' : 'dark'}
                        final={pathPartIdx === path.length - 1}
                        potentialPathParts={potentialPaths[pathPartIdx]}
                        onExpand={handleExpand(pathPartIdx)}
                        expanded={expandedPathIdx === pathPartIdx}
                    />
                ))}
            </span>
        </Context.Provider>
    );
}
