import { useEffect, useState } from 'react';

const listenersByKey = {};

// Save some kind of variable persistently to the browser.
// We could potentially sync this with user preferences as well
// in the future.
function useStorage(storage, key, defaultValue) {
    if (!storage.getItem(key)) {
        storage.setItem(key, JSON.stringify(defaultValue));
    }
    const [value, setValue] = useState(JSON.parse(storage.getItem(key)));

    // Effect to subscribe setState() to adjustments
    // by other `useStorage()` hooks.
    useEffect(() => {
        if (!listenersByKey[key]) {
            listenersByKey[key] = [];
        }
        listenersByKey[key].push(setValue);

        // As cleanup, remove listener.
        return () => {
            listenersByKey[key] = listenersByKey[key].filter(
                (x) => x !== setValue
            );
        };
    }, [key, defaultValue]);

    // Update the value by calling setValue() functions
    // and writing to local storage.
    const updateValue = (newValue) => {
        const listeners = listenersByKey[key] ?? [];
        for (const listener of listeners) {
            listener(newValue);
        }
        storage.setItem(key, JSON.stringify(newValue));
    };

    return [value, updateValue];
}

// Exporting session storage for now because if we break something
// it at least fixes itself on reload. Although changes aren't
// persisted across reload. If we want persistence, use localStorage.
export const useSessionStorage = (...args) =>
    useStorage(sessionStorage, ...args);
