import React from 'react';
import HeroImage from '../../../assets/HeroSectionImage.png';

const HeroSection = () => {
    return (
        <section className="section hero-section">
            <div className="text-column">
                <h1>
                    Take flawless records.<br></br>Instantly.
                </h1>
                <div className="text-column subheading">
                    Doq Copilot writes medical documentation in real time by
                    listening to the natural physician-patient interaction. That
                    way, doctors and staff can focus on the patient.
                </div>
                <div className="button-rack">
                    <button>Book a demo</button>
                    <button>Start using Doq Copilot</button>
                </div>
            </div>
            <div className="image-column">
                <img
                    src={HeroImage}
                    alt="Doq Copilot V1"
                    className="hero-image"
                />
            </div>
        </section>
    );
};

export default HeroSection;
