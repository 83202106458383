import { useRESTRequest } from '../components/Dashboard/serverUtils';
import { AudioRecorder } from './AudioRecorder';
import { ChunkedMediaRecorder } from './ChunkedMediaRecorder';

async function transcribeChunk({ blob, makeRESTRequest }) {
    // Prepare the audio file for upload
    const formData = new FormData();
    formData.append('file', blob, 'recording.webm');

    const response = await makeRESTRequest('POST', '/transcription', formData);

    if (!response) {
        console.error('Unable to transcribe chunk');
    }

    return response?.text;
}

// Usage:
// const handleRecording = useHandleRecording(onTranscription);
// <AudioRecorder handleRecording={handleRecording}>
// The `makeHandleRecording()` function transforms an `onTranscription` handler
// into a factory of event handlers. In other words, it transforms
// a streaming audio recorder into a streaming audio transcriber.
function useHandleRecording(onTranscription) {
    const makeRESTRequest = useRESTRequest();

    return () => {
        const transcribePromises = [];

        return {
            onChunkComplete: (blob) => {
                transcribePromises.push(
                    transcribeChunk({ blob, makeRESTRequest })
                );
            },
            onRecordingComplete: async () => {
                const transcripts = await Promise.all(transcribePromises);
                if (onTranscription) {
                    onTranscription(transcripts);
                } else {
                    console.warn(`No handler for transcripts: ${transcripts}`);
                }
            },
            onRecordingCancelled: () => {
                console.log('Recording cancelled');
            },
        };
    };
}

// `onTranscription` will be called with a list of a single transcript.
export function TranscribeButton({ onTranscription, ...props }) {
    // Automatically transform 1-element list into the element iteslf.
    const handleRecording = useHandleRecording((transcripts) => {
        onTranscription(transcripts[0]);
    });

    return <AudioRecorder {...props} handleRecording={handleRecording} />;
}

// `onTranscription` will be called with a list of overlapping transcripts.
export function StreamingTranscribeButton({
    onTranscription,
    timeSliceMs = 15_000,
    ...props
}) {
    const handleRecording = useHandleRecording(onTranscription);

    return (
        <AudioRecorder
            {...props}
            timeSliceMs={timeSliceMs}
            MediaRecorderClass={ChunkedMediaRecorder}
            onTranscription={onTranscription}
            handleRecording={handleRecording}
        />
    );
}
