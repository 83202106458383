// AppRouter.js
import React from 'react';
import {
    createBrowserRouter,
    RouterProvider,
    redirect,
} from 'react-router-dom';
import { DashboardContainer } from './components/Dashboard';
import IntakeForm from './components/IntakeForm';
import RegistrationForm from './components/RegistrationForm/RegistrationForm';
import './global.css';
import PracticeDashboard from './components/PracticeDashboard/PracticeDashboard';
import SecretaryDashboard from './components/SecretaryDashboard/SecretaryDashboard';
import StationDashboard from './components/StationDashboard/StationDashboard';
import DashboardLogin from './components/DashboardLogin';
import { ErrorBoundary } from './components/ErrorBoundary';
import Website from './components/Website';
import { SuperTokensWrapper } from 'supertokens-auth-react';
import './supertokens';
import { serverUrl } from './config';
import { getSuperTokensRoutesForReactRouterDom } from 'supertokens-auth-react/ui';
import * as reactRouterDom from 'react-router-dom';
import { EmailPasswordPreBuiltUI } from 'supertokens-auth-react/recipe/emailpassword/prebuiltui';
import { makeDevRoutes } from './devRoutes';

function Home() {
    return (
        <>
            <Website />
        </>
    );
}

// React router loader which maps "/dashboard" to a specific dashboard, based on role.
async function loadDashboard() {
    const response = await fetch(`${serverUrl}/auth/role`, { method: 'GET' });
    const { role } = await response.json();
    return redirect(`/dashboard/${role}`);
}

const AppRouter = () => {
    const supertokensRouteElements = getSuperTokensRoutesForReactRouterDom(
        reactRouterDom,
        [EmailPasswordPreBuiltUI]
    );
    const supertokensRoutes = supertokensRouteElements.map((x) => x.props);
    console.log({ supertokensRoutes });

    const router = createBrowserRouter(
        [
            {
                path: '/dashboard/physician',
                key: '/dashboard/physician',
                element: (
                    <DashboardContainer>
                        <PracticeDashboard />
                    </DashboardContainer>
                ),
            },
            {
                path: '/dashboard/secretary',
                key: '/dashboard/secretary',
                element: (
                    <DashboardContainer>
                        <SecretaryDashboard />
                    </DashboardContainer>
                ),
            },
            {
                path: '/dashboard/station',
                key: '/dashboard/station',
                element: (
                    <DashboardContainer>
                        <StationDashboard />
                    </DashboardContainer>
                ),
            },
            { path: '/dashboard', key: '/dashboard', loader: loadDashboard },

            { path: '/intake/*', key: 'intake', element: <IntakeForm /> },
            {
                path: '/register/*',
                key: 'register',
                element: <RegistrationForm />,
            },
            { path: '/login/*', key: 'login', element: <DashboardLogin /> },

            { path: '*', key: 'home', element: <Home /> },

            ...supertokensRoutes,

            ...(process.env.NODE_ENV === 'development' ? makeDevRoutes() : []),
        ].map((route) => ({
            ...route,
            // key: route.key ?? route.path,
            errorElement: <ErrorBoundary />,
        }))
    );

    return (
        <SuperTokensWrapper>
            <RouterProvider router={router} />
        </SuperTokensWrapper>
    );
};

export default AppRouter;
