import React, { useState } from 'react';
import RecordButton from '../../../helpers/RecordButton.helpers';

export const Anamnese = () => {
    const [ownAnamnesis, setOwnAnamnesis] = useState('');
    const [externalAnamnesis, setExternalAnamnesis] = useState('');
    const [ownAnamnesisOutput, setOwnAnamnesisOutput] = useState('');
    const [externalAnamnesisOutput, setExternalAnamnesisOutput] = useState('');

    const handleOwnAnamnesisChange = (event) => {
        setOwnAnamnesis(event.target.value);
    };

    const handleExternalAnamnesisChange = (event) => {
        setExternalAnamnesis(event.target.value);
    };

    const handleOwnAnamnesisOutputChange = (event) => {
        setOwnAnamnesisOutput(event.target.value);
    };

    const handleExternalAnamnesisOutputChange = (event) => {
        setExternalAnamnesisOutput(event.target.value);
    };

    const onRecordingComplete = async () => {};

    return (
        <div className="workspace">
            <div className="anamnesis-card">
                <div className="heading">Eigenanamnese</div>
                <div className="record-field">
                    <textarea
                        type="text"
                        placeholder="Click record or begin typing..."
                        value={ownAnamnesis}
                        onChange={handleOwnAnamnesisChange}
                    />
                    <RecordButton onRecordingComplete={onRecordingComplete} />
                    <button className="generate-button">Generieren</button>
                    <textarea
                        type="text"
                        placeholder="Own Anamnesis Result"
                        value={ownAnamnesisOutput}
                        onChange={handleOwnAnamnesisOutputChange}
                    />
                </div>
            </div>
            <div className="anamnesis-card">
                <div className="heading">Fremdanamnese</div>
                <div className="record-field">
                    <textarea
                        type="text"
                        placeholder="Click record or begin typing..."
                        value={externalAnamnesis}
                        onChange={handleExternalAnamnesisChange}
                    />
                    <RecordButton onRecordingComplete={onRecordingComplete} />
                    <button className="generate-button">Generieren</button>
                    <textarea
                        type="text"
                        placeholder="External Anamnesis Result"
                        value={externalAnamnesisOutput}
                        onChange={handleExternalAnamnesisOutputChange}
                    />
                </div>
            </div>
        </div>
    );
};
