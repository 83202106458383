import { useRouteError } from 'react-router-dom';

import './ErrorBoundary.css';

export function ErrorBoundary() {
    const error = useRouteError();
    console.error(error);

    const handleClick = () => {
        window.location.reload();
    };

    return (
        <div className="error-boundary">
            <p>
                Something went wrong. Try refreshing the page, as that usually
                fixes this kind of thing.
            </p>
            <button onClick={handleClick}>Refresh</button>
        </div>
    );
}
