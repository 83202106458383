import React, { useState } from 'react';
import { AufnahmeToolbox } from './AufnahmeToolbox/AufnahmeToolbox';
import { AufenthaltToolbox } from './AufenthaltToolbox/AufenthaltToolbox';
import { EntlassungToolbox } from './EntlassungToolbox/EntlassungToolbox';
import { ArbeitsdiagnoseToolbox } from './ArbeitsdiagnoseToolbox';
import { TageToolbox } from './TageToolbox';
import { CAVEToolbox } from './CAVEToolbox';
import './StationDashboard.css';

const StationDashboard = () => {
    const [toolbox, setToolbox] = useState('toolbox1');

    const handleToolboxSelection = (toolboxSelection) => {
        setToolbox(toolboxSelection);
    };

    return (
        <div className="station-dashboard">
            <div className="toolbox-selector">
                <button
                    className="aufnahme-toolbox"
                    onClick={() => handleToolboxSelection('toolbox1')}
                >
                    Aufnahme
                </button>
                <button
                    className="aufenthalt-toolbox"
                    onClick={() => handleToolboxSelection('toolbox2')}
                >
                    Stationärer Aufenthalt
                </button>
                <button
                    className="entlassung-toolbox"
                    onClick={() => handleToolboxSelection('toolbox3')}
                >
                    Entlassung
                </button>
                <button
                    className="arbeitsdiagnose-toolbox"
                    onClick={() => handleToolboxSelection('toolbox4')}
                >
                    Arbeitsdiagnose
                </button>
                <button
                    className="tage-toolbox"
                    onClick={() => handleToolboxSelection('toolbox5')}
                >
                    Tage
                </button>
                <button
                    className="cave-toolbox"
                    onClick={() => handleToolboxSelection('toolbox6')}
                >
                    CAVE
                </button>
            </div>
            {toolbox == 'toolbox1' && <AufnahmeToolbox />}
            {toolbox == 'toolbox2' && <AufenthaltToolbox />}
            {toolbox == 'toolbox3' && <EntlassungToolbox />}
            {toolbox == 'toolbox4' && <ArbeitsdiagnoseToolbox />}
            {toolbox == 'toolbox5' && <TageToolbox />}
            {toolbox == 'toolbox6' && <CAVEToolbox />}
        </div>
    );
};

export default StationDashboard;
