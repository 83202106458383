// src/Header.js
import React from 'react';
import './Header.css';
import logo from '../../assets/logoFull.png';
import { PatientSelector } from '../Dashboard/PatientSelector';
import SaveButton from './components/SaveButton';
import { AccountDetails } from './components/AccountDetails';
import { LanguageSwitcher } from './LanguageSwitcher';
import { useNavigate } from 'react-router-dom';
import { GlobalErrorDisplay } from './GlobalErrorDisplay';

const Header = ({ showPatientSelector, showAccountDetails }) => {
    const navigate = useNavigate();

    const handleLogout = () => {
        localStorage.removeItem('token');
        navigate('/login');
    };

    return (
        <div className="header-container">
            <img src={logo} alt="Logo" className="header-logo" />
            <div className="header-tabs-container">
                {showPatientSelector && <PatientSelector />}
                {showPatientSelector && <SaveButton />}
                <GlobalErrorDisplay />
            </div>
            <div className="header-account-container">
                <LanguageSwitcher />
                {showAccountDetails && (
                    <AccountDetails onLogout={handleLogout} />
                )}
            </div>
        </div>
    );
};

export default Header;
