import { useContext, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { CopyIcon } from 'components/Dashboard/CopyIcon';
import { ZoomButton } from 'components/Dashboard/OutputCardHeader';
import { ModalRenderTarget } from 'components/Dashboard/ModalContext';
import { PatientContext } from 'components/Dashboard/PatientContext';
import { useApiQuery } from 'helpers/useApiQuery';
import LoadingIcon from 'helpers/LoadingIcon.helpers';
import {
    DialogueTranscription,
    DialogueTranscriptionHeaderElements,
    useDialogueTranscription,
} from './DialogueTranscription';
import { useInModal } from 'components/Dashboard/ModalContext';
import { StreamingTranscribeButton } from 'helpers/TranscribeButton';
import './DialogueCard.css';
import { DialogueContext } from './DialogueContext';

const SELECT_SUMMARY = 'summary';
const SELECT_TRANSCRIPTION = 'transcription';

const SELECT_VALUES = [SELECT_SUMMARY, SELECT_TRANSCRIPTION];

function Select({ value, onChange }) {
    const { t } = useTranslation();

    const handleChange = (ev) => {
        onChange(ev.target.value);
    };

    return (
        <select
            className="dialogue-select"
            value={value}
            onChange={handleChange}
        >
            {SELECT_VALUES.map((value) => (
                <option key={value} value={value}>
                    {t(`dialogue.select.${value}`)}
                </option>
            ))}
        </select>
    );
}
function ActualTextarea() {
    const { state, dispatch } = useContext(PatientContext);

    return (
        <textarea
            type="text"
            placeholder="Click record or begin typing..."
            value={state.physicianInput.physicianPatientNotes}
            onChange={(e) =>
                dispatch({
                    type: 'SET_PHYSICIAN_PATIENT_NOTES',
                    payload: e.target.value,
                })
            }
        />
    );
}

function DialogueCardContents() {
    const { state, dispatch } = useContext(PatientContext);
    const {
        serverResponse,
        setServerResponse,
        processingIsStale,
        setProcessingIsStale,
    } = useContext(DialogueContext);

    const { t } = useTranslation();
    const query = useApiQuery();
    const inModal = useInModal();
    // State for <Select /> component.
    const [select, setSelect] = useState(SELECT_SUMMARY);

    const dialogueTranscription = useDialogueTranscription();

    const { physicianInput, patientId } = state;
    const { insufficientContent } = serverResponse ?? {};

    const canProcessRecordings = processingIsStale && !insufficientContent;

    const submitQuery = query.submit;

    // Handler for the <StreamingTranscribeButtton> component.
    const onTranscription = async (transcripts) => {
        query.submit({
            method: 'POST',
            path: '/dialogue',
            body: { transcripts, patientId },
            callback: (resp) => {
                if (!resp) return;
                setServerResponse(resp);
            },
        });
    };

    // Handler for the "Process Recordings" button.
    const handleProcessRecordings = async () => {
        // process the transcript and update fields

        if (insufficientContent) {
            alert(
                'Insufficient Content Recorded: Please record more content to continue'
            );
        } else {
            query.submit({
                method: 'POST',
                path: '/dialogue/process-transcript',
                body: { patientId: patientId },
                callback: (resp) => {
                    const newPhysicianInput = resp?.physicianInput;

                    if (newPhysicianInput) {
                        dispatch({
                            type: 'SET_PHYSICIAN_INPUT',
                            payload: newPhysicianInput,
                        });

                        dispatch({
                            type: 'SET_PHYSICIAN_PATIENT_NOTES',
                            payload: newPhysicianInput.physician_patient_notes,
                        });

                        dispatch({
                            type: 'SET_PHYSICAL_EXAMINATION_NOTES',
                            payload:
                                newPhysicianInput.physical_examination_notes,
                        });

                        dispatch({
                            type: 'SET_DIAGNOSIS_NOTES',
                            payload: newPhysicianInput.diagnosis_notes,
                        });

                        dispatch({
                            type: 'SET_TREATMENT',
                            payload: newPhysicianInput.treatment,
                        });

                        setProcessingIsStale(false);
                    }
                },
            });
        }
    };

    // When loading into a new patient, we need to check whether
    // the existing recordings (possibly uploaded by another browser session)
    // are sufficient to produce a summary.
    useEffect(() => {
        if (state.patientId) {
            submitQuery({
                method: 'GET',
                path: `/dialogue/transcript-sufficiency/${state.patientId}`,
                callback: (resp) => {
                    if (!resp) return;

                    setServerResponse(resp);
                },
            });
        }
    }, [submitQuery, state.patientId, setServerResponse]);

    return (
        <>
            <div className="heading gap-10px dialogue-heading">
                <span>{t('physicianPatientNotes')}</span>
                <StreamingTranscribeButton onTranscription={onTranscription} />
                {query.isLoading && <LoadingIcon />}
                {inModal && <Select value={select} onChange={setSelect} />}
                <span className="flex-grow-1" />
                {select === SELECT_TRANSCRIPTION && (
                    <DialogueTranscriptionHeaderElements
                        hook={dialogueTranscription}
                    />
                )}
                <CopyIcon copyText={physicianInput.physicianPatientNotes} />
                <ZoomButton />
            </div>
            {select === SELECT_SUMMARY && (
                <div className="record-field">
                    <ActualTextarea />
                </div>
            )}
            {select === SELECT_TRANSCRIPTION && (
                <DialogueTranscription hook={dialogueTranscription} />
            )}
            <button
                onClick={handleProcessRecordings}
                disabled={!canProcessRecordings}
                className={`process-button ${canProcessRecordings ? 'active' : 'inactive'}`}
            >
                Process Recordings
            </button>
        </>
    );
}

export function DialogueCard() {
    return <ModalRenderTarget render={() => <DialogueCardContents />} />;
}
