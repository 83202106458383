import React from 'react';
import ProblemImage from '../../../assets/problemSectionImage.png';

const ProblemSolutionSection = () => {
    return (
        <section className="section problem-solution-section">
            <div className="image-row">
                <img
                    src={ProblemImage}
                    alt="Documentation Burden"
                    className="problem-image"
                />
            </div>
            <div className="text-row">
                <div className="text-column subheading">
                    <h2>Spend time with patients, not documentation</h2>
                </div>
                <div className="text-column subheading">
                    Doctors spend up to two-thirds of their time writing
                    documentation manually. With Doq Copilot, they don&#39;t.{' '}
                    <br></br>
                    <br></br>
                    Natural conversations between patient and doctor are
                    transcribed and documented in real time. Patient visits
                    become hands-free, focused and comfortable.
                </div>
            </div>
        </section>
    );
};

export default ProblemSolutionSection;
