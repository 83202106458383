import { useRESTRequest } from '../components/Dashboard/serverUtils';
import { useCallback, useRef, useState } from 'react';

// Hook to handle API querying and loading and cancellation.
export function useApiQuery() {
    const [isLoading, _setIsLoading] = useState(false);
    // Store this as a ref as well such that we can access it
    // before waiting for render.
    const loadingRef = useRef(false);

    // Set the loading ref (used to debounce queries)
    // and the loading state (used to display loading indicator).
    const setIsLoading = useCallback((value) => {
        loadingRef.current = value;
        _setIsLoading(value);
    }, []);

    // Cancellation counter. If this value changes during
    // a query's lifetime, then that query should NOT
    // run its callback.
    const cancelled = useRef(0);

    const makeRESTRequest = useRESTRequest();

    const cancel = () => {
        // Close loading icon and immediately allow new queries.
        setIsLoading(false);
        // Update most recent cancellation count
        // to prevent old callbacks from firing.
        cancelled.current += 1;
    };

    const submit = useCallback(
        async ({
            method,
            path,
            body,
            callback,
            errorCallback,
            useRESTRequestOptions,
        }) => {
            const cancelledCount = cancelled.current;

            if (loadingRef.current) return;
            setIsLoading(true);
            let responseData;
            try {
                responseData = await makeRESTRequest(
                    method,
                    path,
                    body,
                    useRESTRequestOptions || {}
                );
            } catch (error) {
                if (errorCallback) {
                    errorCallback(error);
                } else {
                    console.error(error);
                }
            }
            // Only run the callback if the cancelled count
            // is identical to what it was when this query
            // was fired.
            if (responseData && cancelled.current === cancelledCount) {
                callback(responseData);
            }
            setIsLoading(false);
        },
        [setIsLoading, makeRESTRequest]
    );
    return { cancel, isLoading, submit };
}

export function useWithRetries(query, { delayMs }) {
    const timeoutRef = useRef();

    const submitQuery = query.submit;
    const submitWithRetry = useCallback(
        (options) => {
            clearTimeout(timeoutRef.current);
            submitQuery({
                ...options,
                errorCallback: (error) => {
                    clearTimeout(timeoutRef.current);
                    timeoutRef.current = setTimeout(() => {
                        submitWithRetry(options);
                    }, delayMs);
                    options.errorCallback?.(error);
                },
            });
        },
        [submitQuery, delayMs]
    );

    const wrappedSubmit = useCallback(
        (options) => submitWithRetry(options),
        [submitWithRetry]
    );

    const cancelQuery = query.cancel;
    const wrappedCancel = useCallback(() => {
        cancelQuery();

        clearTimeout(timeoutRef.current);
        timeoutRef.current = undefined;
    }, [cancelQuery]);

    return {
        ...query,
        submit: wrappedSubmit,
        cancel: wrappedCancel,
    };
}
