// Single source of truth for current milliseconds (relative only).
// Feel free to change at any point,
// as it just needs to be consistent during page run.
function nowMs() {
    return +new Date();
}

// Transform a callback that can be called many times
// into a callback that is called once, and is a no-op
// afterwards.
function once(callback) {
    let called = false;
    return (...args) => {
        if (!called) {
            called = true;
            callback(...args);
        }
    };
}

// Note that the return type differs from `setTimeout()`.
export function setPausableTimeout(callback, ms) {
    callback = once(callback);

    // Mutable variables within closure.
    let committedMs = 0;
    let timeoutID = setTimeout(callback, ms);
    let timeoutStartMs = nowMs();
    let cancelled = false;

    // Cancel the timer outright.
    const cancel = () => {
        clearTimeout(timeoutID);
        timeoutID = undefined;
        cancelled = true;
    };

    // Pause the timer. Commit the milliseconds
    // from the most recent timespan into the timer.
    const pause = () => {
        committedMs += nowMs() - timeoutStartMs;

        clearTimeout(timeoutID);
        timeoutID = undefined;
        timeoutStartMs = undefined;
    };

    // Resume a paused timer. Internally, use `setTimeout()`
    // but subtract the already-commited milliesconds.
    const resume = () => {
        if (timeoutID) {
            throw new Error('Cannot resume non-paused timer');
        }

        if (cancelled) {
            throw new Error('Cannot resume cancelled timer');
        }

        timeoutID = setTimeout(callback, ms - committedMs);
        timeoutStartMs = nowMs();
    };

    return {
        cancel,
        pause,
        resume,
    };
}

window.setPausableTimeout = setPausableTimeout;
