import LRUCache from 'lru-cache';
import { useEffect, useState } from 'react';
import { EventTargetImpl } from '../../../helpers/EventListener';
import { useApiQuery, useWithRetries } from '../../../helpers/useApiQuery';

export class IcdCodeDetailsCache {
    constructor() {
        this._cache = new LRUCache({ max: 1000 });
        this._eventTarget = new EventTargetImpl();
    }

    addEventListener(name, handler) {
        this._eventTarget.addEventListener(name, handler);
    }
    removeEventListener(name, handler) {
        this._eventTarget.removeEventListener(name, handler);
    }

    put(code, codeDetails) {
        this._cache.set(code, codeDetails);
        this._eventTarget.dispatchEvent({
            code,
            codeDetails,
            type: 'code',
        });
        for (const potentialPathParts of codeDetails.potentialPaths) {
            for (const potentialPathPart of potentialPathParts) {
                this._cache.set(potentialPathPart.name, {
                    fullName: potentialPathPart.fullName,
                    ...(this._cache.get(potentialPathPart.name) ?? {}),
                });
                this._eventTarget.dispatchEvent({
                    code: potentialPathPart.name,
                    codeDetails: this._cache.get(potentialPathPart.name),
                    type: 'code',
                });
            }
        }
    }

    get(code) {
        return this._cache.get(code);
    }

    has(code) {
        return this._cache.has(code);
    }

    fetch({ code, submitQuery }) {
        submitQuery({
            method: 'GET',
            path: `/icd/details?code=${code}`,
            callback: (data) => {
                if (data) {
                    this.put(code, data);
                }
            },
        });
    }
}

// Singleton for global usage.
export const icdCodeDetailsCache = new IcdCodeDetailsCache();

// Custom hook for react usage.
export function useCodeDetails(code) {
    const [codeDetails, setCodeDetails] = useState(
        icdCodeDetailsCache.get(code)
    );
    const query = useWithRetries(useApiQuery(), { delayMs: 5000 });

    // Subscribe to updates about this particular code.
    // Use the 'code' event shared by all codes,
    // but filter for the specific code within the handler.
    const submitQuery = query.submit;
    useEffect(() => {
        setCodeDetails(icdCodeDetailsCache.get(code));

        const handleCodeDetails = (ev) => {
            if (ev.code === code) {
                setCodeDetails(ev.codeDetails);
            }
        };
        icdCodeDetailsCache.addEventListener('code', handleCodeDetails);

        // We trigger a fetch if no cache entry or exists,
        // or if the entry does not contain any potential paths.
        // Entries without potential paths are inserted for potential paths.
        // For example, the entry for "A54.2" is partially inserted
        // when displaying the "A54.2" entry as a potential path
        // for "A54.1".
        const cacheEntry = icdCodeDetailsCache.get(code);
        if (!cacheEntry || !cacheEntry.potentialPaths) {
            icdCodeDetailsCache.fetch({ code, submitQuery });
        }

        return () => {
            icdCodeDetailsCache.removeEventListener('code', handleCodeDetails);
        };
    }, [code, setCodeDetails, submitQuery]);

    return codeDetails;
}
