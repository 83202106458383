import React from 'react';
import './StartScreen.css';
import logo from '../../../assets/logoFull.png';
import { useTranslation } from 'react-i18next';

const StartScreen = ({ handlePageChange }) => {
    const { t } = useTranslation();

    return (
        <div
            className="start-screen"
            onTouchEnd={() => handlePageChange('FixedForm')}
            onClick={() => handlePageChange('FixedForm')}
        >
            <div className="start-screen-logo">
                <img src={logo} alt="Logo" />
            </div>
            <div className="TouchAnywhere">{t('touchAnywhere')}</div>
        </div>
    );
};

export default StartScreen;
