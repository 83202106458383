import { signUp } from 'supertokens-web-js/recipe/emailpassword';

export async function signUpClicked(formData) {
    try {
        let response = await signUp({
            formFields: Object.entries(formData).map(([id, value]) => ({
                id,
                value,
            })),
        });

        if (response.status === 'FIELD_ERROR') {
            // one of the input formFields failed validation
            response.formFields.forEach((formField) => {
                if (formField.id === 'email') {
                    // Email validation failed (for example incorrect email syntax),
                    // or the email is not unique.
                    window.alert(formField.error);
                } else if (formField.id === 'password') {
                    // Password validation failed.
                    // Maybe it didn't match the password strength
                    window.alert(formField.error);
                }
            });
        } else if (response.status === 'SIGN_UP_NOT_ALLOWED') {
            // the reason string is a user friendly message
            // about what went wrong. It can also contain a support code which users
            // can tell you so you know why their sign up was not allowed.
            window.alert(response.reason);
        } else {
            // sign up successful. The session tokens are automatically handled by
            // the frontend SDK.
            window.location.href = '/dashboard';
        }
    } catch (err) {
        if (err.isSuperTokensGeneralError === true) {
            // this may be a custom error message sent from the API by you.
            window.alert(err.message);
        } else {
            console.error(err);
            window.alert('Oops! Something went wrong.');
        }
    }
}
