import React, { useState, useRef, useEffect } from 'react';
import './ChatScreen.css';
import Header from '../../Header';
import { makeRESTRequest } from '../../Dashboard/serverUtils';
import { useTranslation } from 'react-i18next';
import { TranscribeButton } from '../../../helpers/TranscribeButton';

const ChatScreen = ({ token, handlePageChange }) => {
    const { t } = useTranslation();

    // Keeps track of all messages and organizes them by type: 'answer', 'gptquestion' or 'selected'
    const [messages, setMessages] = useState([
        {
            text: t('welcomeToTheClinic'),
            type: 'gpt-question',
        },
    ]);

    const chatBoxRef = useRef(null);

    // OpenAI variables
    const [patientPrompt, setPatientPrompt] = useState('');

    useEffect(() => {
        // Scroll to the bottom of the chat box when a new message is added
        if (chatBoxRef.current) {
            chatBoxRef.current.scrollTop = chatBoxRef.current.scrollHeight;
        }
    }, [messages]);

    const handleSubmit = async (submissionData = patientPrompt) => {
        const prompt =
            typeof submissionData === 'string' ? submissionData : patientPrompt;
        setMessages([...messages, { text: prompt, type: 'submitted-answer' }]);

        removeAnswers();
        await makeRESTRequest(
            'POST',
            '/conversations/chat',
            {
                patientPrompt: prompt,
            },
            {
                headers: { authorization: token },
            }
        )
            .then(async (res) => {
                const content = res.content;

                if (res === 'done') {
                    handlePageChange('EndOfForm');
                    console.log('Session finished');
                    return;
                }

                const gptSplitResponse = content.split('\n- ');
                const formattedResponse = gptSplitResponse.map(
                    (msg, index) => ({
                        text: msg,
                        type:
                            index === 0
                                ? 'gpt-question'
                                : 'gpt-suggested-answer',
                    })
                );

                setMessages((prevMessages) => [
                    ...prevMessages,
                    ...formattedResponse,
                ]);

                setPatientPrompt('');
            })
            .catch((err) => {
                console.error(err);
            });
    };

    const handleAnswerClick = (text) => {
        handleSubmit(text);
    };

    const handleKeyPress = (e) => {
        if (e.key === 'Enter') {
            handleSubmit();
        }
    };

    const removeAnswers = () => {
        setMessages((prevMessages) =>
            prevMessages.filter(
                (prevMessages) => prevMessages.type !== 'gpt-suggested-answer'
            )
        );
    };
    function handleEndOfForm() {
        handlePageChange('EndOfForm');
    }

    const handleTranscription = (transcription) => {
        setPatientPrompt(transcription);
    };

    return (
        <div className="chat-screen">
            <Header showPatientSelector={false} />
            <div className="end-session-early-button">
                <button onClick={() => handleEndOfForm()}>
                    {t('endSessionEarly')}
                </button>
            </div>
            <div className="intake-container">
                <div className="gradient-overlay"></div>{' '}
                <div className="intake-box" ref={chatBoxRef}>
                    {messages.map((message, index) => (
                        <div key={index} className={`${message.type}`}>
                            {message.type === 'gpt-suggested-answer' ? (
                                <button
                                    className="gpt-suggested-answer"
                                    onClick={() => {
                                        handleAnswerClick(message.text);
                                    }}
                                >
                                    {message.text}
                                </button>
                            ) : message.type === 'submitted-answer' ? (
                                <>{message.text}</>
                            ) : message.type === 'gpt-question' ? (
                                <>{message.text}</>
                            ) : (
                                <>{message.text}</>
                            )}
                        </div>
                    ))}
                </div>
                <div className="intake-input">
                    <input
                        type="text"
                        value={patientPrompt}
                        autoFocus="autoFocus"
                        onChange={(e) => setPatientPrompt(e.target.value)}
                        onKeyDown={handleKeyPress}
                    />
                    <button onClick={handleSubmit}>{t('send')}</button>
                    <TranscribeButton onTranscription={handleTranscription} />
                </div>
            </div>
        </div>
    );
};

export default ChatScreen;
