import LoadingIcon from '../../../helpers/LoadingIcon.helpers';
import { Breadcrumbs } from './Breadcrumbs';
import './IcdDisplay.css';

// Display component for display of an ICD diagnosis code.
export function IcdDisplay({
    path,
    potentialPaths,
    onChangePath,
    fullName,
    isLoading,
    onRemove,
    onApply,
    onDismiss,
}) {
    return (
        <div className="icd-display">
            <div className="icd-display-header">
                <Breadcrumbs {...{ path, potentialPaths, onChangePath }} />
                <span className="fullname">{fullName}</span>
                {isLoading && (
                    <LoadingIcon iconClassName="rotating-icon no-vert-margin" />
                )}
                <span className="flex-grow-1" />
                {onRemove && <button onClick={onRemove}>Remove</button>}
                {onDismiss && <button onClick={onDismiss}>Dismiss</button>}
                {onApply && <button onClick={onApply}>Apply</button>}
            </div>
        </div>
    );
}
