import React from 'react';
import { AudioRecorder, useAudioRecorder } from '../helpers/AudioRecorder';

export default function RecordButton({ onRecordingComplete }) {
    const recorderControls = useAudioRecorder(
        {
            noiseSuppression: true,
            echoCancellation: true,
        },
        (err) => console.table(err) // onNotAllowedOrFound
    );

    const handleRecording = () => ({
        onChunkComplete: onRecordingComplete,
        // These events can be ignored because
        // chunks are only output for full recordings,
        // as we set timeslice=undefined.
        onRecordingComplete: () => {},
        onRecordingCancelled: () => {},
    });

    return (
        <div className="record-button-container">
            <AudioRecorder
                onRecordingComplete={onRecordingComplete}
                recorderControls={recorderControls}
                downloadFileExtension="webm"
                showVisualizer={true}
                handleRecording={handleRecording /*for custom*/}
            />
        </div>
    );
}
