import React, { useContext } from 'react';
import { PatientContext } from '../Dashboard/PatientContext';
import { SearchBarBody, SearchBarHeader, SearchBarProvider } from './SearchBar';
import { CopyIcon } from '../Dashboard/CopyIcon';
import { useApiQuery } from '../../helpers/useApiQuery';
import LoadingIcon from '../../helpers/LoadingIcon.helpers';
import { useTranslation } from 'react-i18next';
import {
    OutputCardProvider,
    OutputCardBody,
    OutputCardFooter,
} from '../Dashboard/OutputCard';
import {
    ContextualSuggestButton,
    ZoomButton,
} from '../Dashboard/OutputCardHeader';
import { OutputCardContext } from '../Dashboard/OutputCardContext';
import { ModalRenderTarget } from '../Dashboard/ModalContext';
import './DiagnosisCard.css';

// Main component of diagnosis card, which is a child of OutputCardContextProvider.
function InnerDiagnosisCard() {
    const {
        state: { physicianInput },
        dispatch,
    } = useContext(PatientContext);
    const { t } = useTranslation();

    const outputCardContext = useContext(OutputCardContext);
    // What has the user typed in the text box at the bottom of this card?
    const userText = outputCardContext.data;

    // For use in search bar. However, we allocate these here
    // such that we can track whether they are loading.
    const suggestedDiagnosesQuery = useApiQuery();
    const availableDiagnosesQuery = useApiQuery();

    const handleDiagnosisSelect = (diagnosis) => {
        dispatch({ type: 'APPEND_DIAGNOSIS', payload: diagnosis });
    };

    const diagnosesText =
        physicianInput.diagnoses
            .map((x) => `${x.code} -- ${x.codeBezeichnung}`)
            .join('\n') +
        '\n\n' +
        userText;

    const isLoading =
        suggestedDiagnosesQuery.isLoading ||
        availableDiagnosesQuery.isLoading ||
        outputCardContext.query.isLoading;

    return (
        <>
            <div className="heading justify-content-space-between gap-10px">
                {t('diagnosis')}
                {isLoading && <LoadingIcon />}
                <span className="flex-grow-1" />
                <CopyIcon
                    copyText={diagnosesText}
                    between={
                        <span className="padding-5px">
                            <ContextualSuggestButton />
                        </span>
                    }
                />
                <ZoomButton />
            </div>
            {/* We build the search bar out of its components manually.
                This ensures that the searchbar body is scrollable,
                but the header is not. */}
            <SearchBarProvider
                onDiagnosis={handleDiagnosisSelect}
                selectedItems={physicianInput.diagnoses}
                suggestedDiagnosesQuery={suggestedDiagnosesQuery}
                availableDiagnosesQuery={availableDiagnosesQuery}
            >
                <SearchBarHeader />
                <div className="diagnosis-card-body">
                    <SearchBarBody />
                    <hr />
                    <OutputCardBody className="flex-grow-1" />
                    <OutputCardFooter />
                </div>
            </SearchBarProvider>
        </>
    );
}

// Component we actually export,
// with all bells and whistles attached / wired up.
export function DiagnosisCard() {
    return (
        <ModalRenderTarget
            render={() => (
                <OutputCardProvider showHeader={false} category="diagnosis">
                    <InnerDiagnosisCard />
                </OutputCardProvider>
            )}
        />
    );
}
