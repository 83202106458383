import React from 'react';
import { useState, useEffect, useContext, useCallback } from 'react';
import { PatientContext, getAllPatients } from './PatientContext';
import LoadingIcon from '../../helpers/LoadingIcon.helpers';
import './PatientSelector.css';
import { IoRefresh } from 'react-icons/io5';
import { useRESTRequest } from './serverUtils';
import { useTranslation } from 'react-i18next';

export const PatientSelector = () => {
    const [patients, setPatients] = useState([]);
    // NOTE: If this logic starts acting up, ditch the trigger
    const [fetchTrigger, setFetchTrigger] = useState(0);
    const [loading, setLoading] = useState(false);

    const {
        state: { patientId, physicianInput },
        dispatch,
    } = useContext(PatientContext);
    const { t } = useTranslation();

    const makeRESTRequest = useRESTRequest();

    const fetchPatients = useCallback(() => {
        (async () => {
            const patients = await getAllPatients(makeRESTRequest);
            setPatients(patients);
        })();
    }, [makeRESTRequest]);

    useEffect(fetchPatients, [fetchTrigger, setFetchTrigger, fetchPatients]);

    const labelFromPatientInfo = (patientInfo, id) => {
        if (!patientInfo) {
            return t('newPatient', { id });
        }

        return t('patientLabel', patientInfo);
    };

    const handlePatientSelected = useCallback(
        async (event) => {
            setLoading(true);

            const selectedValue = event.target.value;
            if (
                !physicianInput.dirty ||
                window.confirm('Discard changes to the current patient?')
            ) {
                if (selectedValue === 'new') {
                    try {
                        const data = await makeRESTRequest(
                            'POST',
                            '/patients/new',
                            {}
                        );

                        dispatch({
                            type: 'SET_PATIENT_ID',
                            payload: data.patientId,
                        });

                        setFetchTrigger((prev) => prev + 1);
                    } catch (error) {
                        console.error('Error creating new session:', error);
                    }
                } else {
                    dispatch({
                        type: 'SET_PATIENT_ID',
                        payload: selectedValue,
                    });
                }
                setLoading(false);
            }
        },
        [dispatch, physicianInput.dirty, makeRESTRequest]
    );

    return (
        <div className="patient-selector">
            {loading && <LoadingIcon />}
            {!loading && (
                <>
                    <button
                        type="button"
                        className="refresh-button"
                        onClick={fetchPatients}
                    >
                        <IoRefresh />
                    </button>
                </>
            )}
            <select value={patientId} onChange={handlePatientSelected}>
                <option value="">--{t('selectAPatient')}--</option>
                <option value="new" className="add-new-session-button">
                    {t('createNewSession')}
                </option>
                {patients.map((patient) => (
                    <option key={patient.id} value={patient.id}>
                        {labelFromPatientInfo(patient.info, patient.id)}
                    </option>
                ))}
            </select>
        </div>
    );
};
