import { useEffect, useRef } from 'react';

// Trigger an effect that runs when
// invariant holds the same value across 2 consecutive renders,
// but variant changes. For example, the same patient is selected
// but the language has changed.
//
// In other words, run the effect when the variant swap values.
// (But we track the invariant to make sure
// this still represents the thing we're interested in ).
//
// There are cleaner solutions, but this is a winner
// in terms of minimizing code modification.
export function useSwapEffect({ invariant, variant, callback }) {
    const ref = useRef();

    useEffect(() => {
        const prev = ref.current;

        if (prev && prev.invariant === invariant && prev.variant !== variant) {
            callback();
        }

        ref.current = { invariant, variant };
    }, [invariant, variant, callback]);
}
