export function filterUnique(items, keyFunc) {
    const found = new Set();
    const newItems = [];

    for (const item of items) {
        const key = keyFunc(item);
        if (!found.has(key)) {
            newItems.push(item);
        }
        found.add(key);
    }
    return newItems;
}
