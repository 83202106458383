import React, { useState } from 'react';
import { Anamnese } from './Anamnese';
import { InternistischeUntersuchung } from './InternistischeUntersuchung';
import { NeurologischeUntersuchung } from './NeurologischeUntersuchung';
import { PPB } from './PPB';
import './AufnahmeToolbox.css';

export const AufnahmeToolbox = () => {
    const [tool, setTool] = useState('tool1');

    const handleToolSelection = (toolSelection) => {
        setTool(toolSelection);
    };

    return (
        <div className="toolbox-content">
            <div className="tool-selector">
                <button
                    className="anamnese"
                    onClick={() => handleToolSelection('tool1')}
                >
                    Anamnese
                </button>
                <button
                    className="internistische-untersuchung"
                    onClick={() => handleToolSelection('tool2')}
                >
                    Internistische Untersuchung
                </button>
                <button
                    className="neurologische-untersuchung"
                    onClick={() => handleToolSelection('tool3')}
                >
                    Neurologische Untersuchung
                </button>
                <button
                    className="ppb"
                    onClick={() => handleToolSelection('tool4')}
                >
                    PPB
                </button>
                <button
                    className="allergien-intoleranzen"
                    onClick={() => handleToolSelection('tool5')}
                >
                    Allergien/Intoleranzen
                </button>
                <button
                    className="aufnahme-diagnose"
                    onClick={() => handleToolSelection('tool6')}
                >
                    Aufnahmediagnose
                </button>
                <button
                    className="mitgebrachte-dokumente"
                    onClick={() => handleToolSelection('tool7')}
                >
                    Mitgebrachte Dokumente
                </button>
                <button
                    className="juristische-sachlage"
                    onClick={() => handleToolSelection('tool8')}
                >
                    Juristische Sachlage
                </button>
                <button
                    className="todo-station"
                    onClick={() => handleToolSelection('tool9')}
                >
                    To-Do für die Station
                </button>
            </div>
            {tool == 'tool1' && <Anamnese />}
            {tool == 'tool2' && <InternistischeUntersuchung />}
            {tool == 'tool3' && <NeurologischeUntersuchung />}
            {tool == 'tool4' && <PPB />}
            {tool == 'tool5' && <p>Allergien/Intoleranzen</p>}
            {tool == 'tool6' && <p>Aufnahmediagnose</p>}
            {tool == 'tool7' && <p>Mitgebrachte Dokumente</p>}
            {tool == 'tool8' && <p>Juristische Sachlage</p>}
            {tool == 'tool9' && <p>To-Do für die Station</p>}
        </div>
    );
};
