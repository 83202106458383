import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FaX } from 'react-icons/fa6';

import './GlobalErrorDisplay.css';

let setErrorHandles = [];

export function throwNamedError(name, error) {
    for (const setError of setErrorHandles) {
        setError(name);
    }
    console.error(error);

    // Return a function which cancels the error.
    // If any displays are displaying an error
    // with the name of this error,
    // then they will be reset to the empty display.
    return () => {
        for (const setError of setErrorHandles) {
            setError((error) => (error === name ? undefined : error));
        }
    };
}

// Error display to catch all non-rendering errors
// and unhandled promise rejections.
export function GlobalErrorDisplay() {
    // State to store the name of the current uncleared error.
    // This is interpreted as an i18n translation key.
    const [error, setError] = useState();

    // Update the error whenever one occurs on the window,
    // or is dispatched to us manually (with a name!)
    useEffect(() => {
        const handleError = (error) => {
            setError('error.generic');
            console.error(error);
        };
        window.addEventListener('error', handleError);
        window.addEventListener('unhandledrejection', handleError);
        setErrorHandles.push(setError);
        return () => {
            window.removeEventListener('error', handleError);
            window.removeEventListener('unhandledrejection', handleError);
            setErrorHandles = setErrorHandles.filter((x) => x !== setError);
        };
    }, [setError]);

    // Use i18n to translate the current error
    // according to the current language.
    const { t } = useTranslation();

    // When the "X" is clicked, clear the error.
    const handleClick = () => {
        setError(undefined);
    };

    return (
        error && (
            <span className="error-display">
                <span>{t(error)}</span>
                <button onClick={handleClick}>
                    <FaX />
                </button>
            </span>
        )
    );
}
