// Like the builtin MediaRecorder class,
// but records independent chunks that are
// immediately suitable for server processing.
// Every chunk includes all of the required metadata,
// so no alignment is required.
//
// Overlap is supported, such that adjacent chunks
// will include the same audio between them.
// Useful for transcription, where we would rather get two copies
// of a span than chop said span up mid-word.
//
// This component aims to make minimal changes to
// the interface, so you still listen for 'datavailable',
// 'stop', and 'error' events using the standard
// `.addEventListener()` method.
//
// The theory behind this class is we basically
// hold 2 media recorders and start/stop recordings
// on each of them to ensure constant coverage.

import { EventTargetImpl } from './EventListener';
import { setPausableTimeout } from './setPausableTimeout';

export const STATE_INACTIVE = 'inactive';
export const STATE_RECORDING = 'recording';
export const STATE_PAUSED = 'paused';

const CHUNK_MS = 10_000;
const OVERLAP_MS = 2_000;

export class ChunkedMediaRecorder {
    // Support the same constructor as `MediaRecorder`.
    constructor(stream, options) {
        this._mediaRecorders = [
            new MediaRecorder(stream, options),
            new MediaRecorder(stream, options),
        ];
        // `this._expectStop[i]` indicates whether the `ChunkedMediaRecorder`
        // has called `this._mediaRecorders[i].stop()`,
        // and therefore expects a 'stop' event soon.
        this._expectStop = [false, false];

        this._state = STATE_INACTIVE;
        this._eventTarget = new EventTargetImpl();
        this._pausableTimeouts = [];

        this._addDataAvailableEventListeners();
        this._addStopEventListeners();
    }

    // Wire up the 'datavailable' event of
    // an under-the-hood media recorder
    // directly to the outer 'dataavailable' event.
    _addDataAvailableEventListeners() {
        for (const mediaRecorder of this._mediaRecorders) {
            mediaRecorder.addEventListener('dataavailable', (ev) => {
                this._eventTarget.dispatchEvent(ev);
            });
        }
    }

    // We need to listen to stop events of individual media recorders
    // in case one of them stops outside of a call to
    // `ChunkedMediaRecorder.stop()`. Also, to ensure that we have given
    // the media recorders time to output their final 'datavailable' event
    // before we dispatch the 'stop' event.
    _addStopEventListeners() {
        for (const [idx, mediaRecorder] of this._mediaRecorders.entries()) {
            mediaRecorder.addEventListener('stop', () => {
                // If all media recorders are now inactive,
                // then the stop is complete.
                if (
                    [...this._mediaRecorders.values()].every(
                        (x) => x.state === STATE_INACTIVE
                    )
                ) {
                    this._eventTarget.dispatchEvent(new Event('stop'));
                }

                // If we are still waiting for the timeout
                // to stop this media recorder,
                // then it is unexpected.
                if (this._expectStop[idx]) {
                    console.log('normal media recorder stop');
                } else {
                    console.warn('unexpected media recorder stop');
                    // Stop the overall recorder.
                    this._stop();
                }
                this._expectStop[idx] = false;
            });
        }
    }

    start() {
        if (this.state !== STATE_INACTIVE) {
            throw new Error(
                `Can only start recorder from '${STATE_INACTIVE}' state`
            );
        }

        // Arbitrarily, start the 0th underlying recorder.
        // This will also queue up other recorders via `setTimeout()`.
        this._startMediaRecorder(0);

        this._state = STATE_RECORDING;
    }

    stop() {
        if (this.state === STATE_INACTIVE) {
            throw new Error(
                `Cannot stop recorder from '${STATE_INACTIVE}' state`
            );
        }

        this._stop();
    }

    // Variant with no checks, for auto-stopping.
    _stop() {
        // NOTE: do we need to worry about
        // events being fired in the wrong order here?
        // For example, if `this._mediaRecorders[0]` is
        // actually in front of `this._mediaRecorders[1]`,
        // then we still want its chunk to come after.
        //
        // In general, maybe we need to track the start time of each recorder
        // to avoid this kind of issue.
        for (const [idx, mediaRecorder] of this._mediaRecorders.entries()) {
            if (mediaRecorder.state !== STATE_INACTIVE) {
                this._stopMediaRecorder(idx);
            }
        }

        for (const pausableTimeout of this._pausableTimeouts) {
            // The pausable timeouts start and stop media recorders.
            // Since we've already stopped all media recorders,
            // cancelling the pausable timeouts will prevent
            // new recorders from starting.
            //
            // So nothing should happen until
            // the next `ChunkedMediaRecorder.start()` call.
            pausableTimeout.cancel();
        }

        this._state = STATE_INACTIVE;
    }

    pause() {
        if (this.state !== STATE_RECORDING) {
            throw new Error(`Can only pause from '${STATE_RECORDING}' state`);
        }

        for (const mediaRecorder of this._mediaRecorders) {
            if (mediaRecorder.state === STATE_RECORDING) {
                mediaRecorder.pause();
            }
        }

        for (const pausableTimeout of this._pausableTimeouts) {
            pausableTimeout.pause();
        }

        this._state = STATE_PAUSED;
    }

    resume() {
        if (this._state !== STATE_PAUSED) {
            throw new Error(`Can only resume from '${STATE_PAUSED}' state`);
        }

        for (const mediaRecorder of this._mediaRecorders) {
            if (mediaRecorder.state === STATE_PAUSED) {
                mediaRecorder.resume();
            }
        }

        for (const pausableTimeout of this._pausableTimeouts) {
            pausableTimeout.pause();
        }
    }

    // Forward event methods to our event target instance.
    // Easier to reason about than inheritance.
    addEventListener(name, handler) {
        this._eventTarget.addEventListener(name, handler);
    }

    removeEventListener(name, handler) {
        this._eventTarget.addEventListener(name, handler);
    }

    // Start `this.mediaRecorders[idx]` and the corresponding timers
    // that complete the stream.
    _startMediaRecorder(idx) {
        this._mediaRecorders[idx].start();

        // Store pausable timeouts such that we can pause/resume them later.
        this._pausableTimeouts.push(
            // After (chunk-overlap), start the next recorder.
            setPausableTimeout(() => {
                this._startMediaRecorder(
                    (idx + 1) % this._mediaRecorders.length
                );
            }, CHUNK_MS - OVERLAP_MS),
            // After (chunk), stop this recorder.
            setPausableTimeout(() => {
                this._stopMediaRecorder(idx);
            }, CHUNK_MS)
        );
    }

    // Stop `this._mediaRecorders[idx]`,
    // but do not adjust any of the timeouts.
    _stopMediaRecorder(idx) {
        this._expectStop[idx] = true;
        this._mediaRecorders[idx].stop();
    }

    get state() {
        return this._state;
    }
}
