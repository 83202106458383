import React, { useState } from 'react';
import './FixedForm.css';
import Header from '../../Header';
import { useRESTRequest } from '../../Dashboard/serverUtils';
import { IoRefresh } from 'react-icons/io5';
import { useTranslation } from 'react-i18next';

const FixedForm = ({ handlePageChange, handleToken }) => {
    const [fixedFormData, setFixedFormData] = useState({
        firstName: '',
        lastName: '',
        organizationId: '',
        practitionerEmail: '',
        age: '',
        gender: '',
    });
    const [users, setUsers] = useState([]);
    const [orgIdVerified, setOrgIdVerified] = useState(false);
    const { t } = useTranslation();
    const makeRESTRequest = useRESTRequest();

    const fetchUsers = async () => {
        const updatedUserList = await makeRESTRequest(
            'GET',
            `/users/${fixedFormData.organizationId}`
        );
        setUsers(updatedUserList);
        console.log(updatedUserList);
    };

    const handleUserSelected = (e) => {
        setFixedFormData({
            ...fixedFormData,
            practitionerEmail: e.target.value,
        });
    };

    const handleFixedFormChange = (e) => {
        const { name, value } = e.target;
        setFixedFormData({ ...fixedFormData, [name]: value });
    };

    const handleOrgAuthChange = (e) => {
        setFixedFormData({ ...fixedFormData, organizationId: e.target.value });
    };

    const handleOrgAuthSubmit = async (e) => {
        e.preventDefault();
        setOrgIdVerified(false);

        makeRESTRequest('POST', 'auth/organization', {
            organizationId: fixedFormData.organizationId,
        }).then(async (res) => {
            if (res) {
                console.log('verified');
                await fetchUsers();
                setOrgIdVerified(true);
            } else {
                alert('Invalid Organization ID');
            }
        });
    };

    const handleFixedFormSubmit = async (e) => {
        e.preventDefault();
        await makeRESTRequest('POST', 'conversations/startChat', {
            fixedFormData,
        }).then(async (res) => {
            handleToken(res.token);
        });

        handlePageChange('DataProtectionAgreement');
    };

    const labelFromUser = (user) => {
        if (!user) {
            return '';
        }

        return `${user.title} ${user.firstName} - ${user.lastName}`;
    };

    return (
        <div className="fixed-form-page">
            <Header showPatientSelector={false} showAccountDetails={false} />
            {!orgIdVerified && (
                <>
                    <div className="fixed-form-container">
                        <form onSubmit={handleOrgAuthSubmit}>
                            <div className="entry-container">
                                <label>
                                    Organization ID:
                                    <br />
                                    <input
                                        type="text"
                                        name="organizationId"
                                        placeholder={t('enterOrganizationId')}
                                        value={fixedFormData.organizationId}
                                        onChange={handleOrgAuthChange}
                                        required
                                    />
                                </label>
                            </div>
                            <div className="submit-organization-button">
                                <button type="submit">Continue</button>
                            </div>
                        </form>
                    </div>
                </>
            )}
            {orgIdVerified && (
                <>
                    <div className="fixed-form-container">
                        <h2>{t('tellUsAboutYourself')}</h2>
                        <form onSubmit={handleFixedFormSubmit}>
                            <div className="entry-container">
                                <label>{t('firstName')}</label>
                                <input
                                    type="text"
                                    name="firstName"
                                    placeholder={t('enterFirstName')}
                                    value={fixedFormData.firstName}
                                    onChange={handleFixedFormChange}
                                    required
                                />
                            </div>
                            <div className="entry-container">
                                <label>{t('lastName')}</label>
                                <input
                                    type="text"
                                    name="lastName"
                                    placeholder={t('enterLastName')}
                                    value={fixedFormData.lastName}
                                    onChange={handleFixedFormChange}
                                    required
                                />
                            </div>
                            <div className="entry-container">
                                <label>{t('age')}</label>
                                <input
                                    type="text"
                                    name="age"
                                    placeholder={t('enterAge')}
                                    value={fixedFormData.age}
                                    onChange={handleFixedFormChange}
                                    required
                                />
                            </div>
                            <div className="entry-container">
                                <label>{t('gender')}</label>
                                <input
                                    type="text"
                                    name="gender"
                                    placeholder={t('enterGender')}
                                    value={fixedFormData.gender}
                                    onChange={handleFixedFormChange}
                                    required
                                />
                            </div>
                            <div className="entry-container">
                                <div className="physician-selection-container">
                                    <label>{t('yourPhysician')}</label>
                                    <button
                                        type="button"
                                        className="refresh-users-button"
                                        onClick={fetchUsers}
                                    >
                                        <IoRefresh />
                                    </button>
                                </div>
                                <select
                                    value={fixedFormData.practitionerEmail}
                                    onChange={handleUserSelected}
                                    required
                                >
                                    <option value="">
                                        {t('selectAPhysician')}
                                    </option>
                                    {users.map((user) => (
                                        <option
                                            key={user.email}
                                            value={user.email}
                                        >
                                            {labelFromUser(user)}
                                        </option>
                                    ))}
                                </select>
                            </div>
                            <div className="submit-button">
                                <button type="submit">{t('continue')}</button>
                            </div>
                        </form>
                    </div>
                </>
            )}
        </div>
    );
};

export default FixedForm;
