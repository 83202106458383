import { useEffect } from 'react';

// Fire a callback whenever a DOM element beyond the supplied ref is clicked.
export function useClickAway(ref, callback) {
    useEffect(() => {
        const handleClick = (ev) => {
            if (!ref.current.contains(ev.target)) {
                callback();
            }
        };
        window.addEventListener('click', handleClick);
        return () => {
            window.removeEventListener('click', handleClick);
        };
    }, [ref, callback]);
}
