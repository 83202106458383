import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

const SHOW_COPIED_MILLISECONDS = 5000;

// Button that copies `copyText` to clipboard,
// and displays "Copied To Clipboard" for a few seconds.
// Otherwise, displays "Copy".
export const CopyButton = ({ copyText }) => {
    const [haveCopiedRecently, setHaveCopiedRecently] = useState(false);
    const timeoutRef = useRef(0);
    const { t } = useTranslation();

    // Function to copy `copyText` to clipboard.
    // We may need additional handling for legacy browsers.
    const handleClickCopy = () => {
        navigator.clipboard.writeText(copyText);
        setHaveCopiedRecently(true);
        clearTimeout(timeoutRef.current);
        timeoutRef.current = setTimeout(() => {
            setHaveCopiedRecently(false);
        }, SHOW_COPIED_MILLISECONDS);
    };

    // If `copyText` changes, then we should reset the text to "Copy".
    useEffect(() => {
        setHaveCopiedRecently(false);
        clearTimeout(timeoutRef.current);
    }, [copyText]);

    const label = haveCopiedRecently ? t('copied') : t('copy');

    return (
        <div className="summary-bottom">
            <button className="custom-file-upload" onClick={handleClickCopy}>
                {label}
            </button>
        </div>
    );
};
