import React, { useState } from 'react';

export const EntlassungToolbox = () => {
    const [tool, setTool] = useState('tool1');

    const handleToolSelection = (toolSelection) => {
        setTool(toolSelection);
    };

    return (
        <div className="toolbox-content">
            <div className="tool-selector">
                <button
                    className="aufnahmebogen"
                    onClick={() => handleToolSelection('tool1')}
                >
                    Aufnahmebogen
                </button>
                <button
                    className="verlaeufe"
                    onClick={() => handleToolSelection('tool2')}
                >
                    Verläufe
                </button>
                <button
                    className="medikamenten-verlauf"
                    onClick={() => handleToolSelection('tool3')}
                >
                    Medikamenten Verlauf
                </button>
                <button
                    className="auffaellige-blutwerte-im-verlauf"
                    onClick={() => handleToolSelection('tool4')}
                >
                    Auffällige Blutwerte im Verlauf
                </button>
                <button
                    className="befunde"
                    onClick={() => handleToolSelection('tool5')}
                >
                    Befunde
                </button>
                <button
                    className="ris-befunde"
                    onClick={() => handleToolSelection('tool6')}
                >
                    RIS Befunde
                </button>
                <button
                    className="aenderungen-der-juristischen-sachlage"
                    onClick={() => handleToolSelection('tool7')}
                >
                    Änderungen der Juristischen Sachlage
                </button>
                <button
                    className="diagnosen-icd-10"
                    onClick={() => handleToolSelection('tool8')}
                >
                    Diagnosen ICD-10
                </button>
                <button
                    className="epicrise"
                    onClick={() => handleToolSelection('tool9')}
                >
                    Epicrise
                </button>
            </div>
            <div className="workspace">
                {tool == 'tool1' && <p>Aufnahmebogen</p>}
                {tool == 'tool2' && <p>Verläufe</p>}
                {tool == 'tool3' && <p>Medikamenten Verlauf</p>}
                {tool == 'tool4' && <p>Auffällige Blutwerte im Verlauf</p>}
                {tool == 'tool5' && <p>Befunde</p>}
                {tool == 'tool6' && <p>RIS Befunde</p>}
                {tool == 'tool7' && <p>Änderungen der Juristischen Sachlage</p>}
                {tool == 'tool8' && <p>Diagnosen ICD-10</p>}
                {tool == 'tool9' && <p>Epicrise</p>}
            </div>
        </div>
    );
};
