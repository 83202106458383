import SuperTokens from 'supertokens-auth-react';
import EmailPassword from 'supertokens-auth-react/recipe/emailpassword';
import Session from 'supertokens-auth-react/recipe/session';
import { serverUrl } from './config';

SuperTokens.init({
    appInfo: {
        // learn more about this on https://supertokens.com/docs/emailpassword/appinfo
        appName: 'doq',
        apiDomain: serverUrl,
        websiteDomain: 'http://localhost:3006/',
        apiBasePath: '/api/auth',
        websiteBasePath: '/auth',
    },
    recipeList: [EmailPassword.init(), Session.init()],
});
