import React from 'react';
import './EpicrisisColumn.css';
import { OutputCard } from '../Dashboard/OutputCard';
import { DiagnosisCard } from './DiagnosisCard';
import { useTranslation } from 'react-i18next';

export const EpicrisisColumn = () => {
    const { t } = useTranslation();

    return (
        <div className="epicrisis-column">
            <div className="card diagnosis-card">
                <DiagnosisCard />
            </div>
            <div className="card">
                <OutputCard
                    showHeader={true}
                    title={t('treatment')}
                    category="treatment"
                />
            </div>
            <div className="card epicrisis-card">
                <OutputCard
                    showHeader={true}
                    title={t('epicrisis')}
                    category="epicrisis"
                />
            </div>
        </div>
    );
};
