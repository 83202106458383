// src/PatientReducer.jsx

import { emptyPhysicianInput } from './PatientContext';
import { filterUnique } from '../../helpers/filterUnique';

export const patientReducer = (state, action) => {
    switch (action.type) {
        case 'SET_PATIENT_ID':
            return {
                patientId: action.payload,
                physicianInput: emptyPhysicianInput(),
            };
        case 'SET_PHYSICIAN_INPUT':
            return {
                ...state,
                physicianInput: action.payload,
            };
        case 'SET_DIAGNOSIS_NOTES':
            return {
                ...state,
                physicianInput: {
                    ...state.physicianInput,
                    dirty: true,
                    diagnosisNotes: action.payload,
                },
            };
        case 'SET_TREATMENT':
            return {
                ...state,
                physicianInput: {
                    ...state.physicianInput,
                    dirty: true,
                    treatment: action.payload,
                },
            };
        case 'SET_PHYSICIAN_PATIENT_NOTES':
            return {
                ...state,
                physicianInput: {
                    ...state.physicianInput,
                    dirty: true,
                    physicianPatientNotes: action.payload,
                },
            };
        case 'SET_PHYSICAL_EXAMINATION_NOTES':
            return {
                ...state,
                physicianInput: {
                    ...state.physicianInput,
                    dirty: true,
                    physicalExaminationNotes: action.payload,
                },
            };
        case 'SET_ANAMNESIS':
            return {
                ...state,
                physicianInput: {
                    ...state.physicianInput,
                    dirty: true,
                    anamnesis: action.payload,
                },
            };
        case 'SET_EPICRISIS':
            return {
                ...state,
                physicianInput: {
                    ...state.physicianInput,
                    dirty: true,
                    epicrisis: action.payload,
                },
            };
        case 'APPEND_DIAGNOSIS':
            return {
                ...state,
                physicianInput: {
                    ...state.physicianInput,
                    dirty: true,
                    diagnoses: [
                        ...state.physicianInput.diagnoses,
                        action.payload,
                    ],
                },
            };
        case 'REMOVE_DIAGNOSIS':
            return {
                ...state,
                physicianInput: {
                    ...state.physicianInput,
                    dirty: true,
                    diagnoses: state.physicianInput.diagnoses
                        // Remove diagnosis with specified code.
                        .filter(
                            (diagnosis) => diagnosis.code !== action.payload
                        )
                        // Shallow copy.
                        .map((x) => ({ ...x })),
                },
            };
        case 'UPDATE_DIAGNOSIS':
            return {
                ...state,
                physicianInput: {
                    ...state.physicianInput,
                    dirty: true,
                    diagnoses: filterUnique(
                        state.physicianInput.diagnoses.map((diagnosis) => ({
                            ...diagnosis,
                            code:
                                diagnosis.code === action.payload.oldCode
                                    ? action.payload.newCode
                                    : diagnosis.code,
                        })),
                        (item) => item.code
                    ),
                },
            };
        case 'MARK_PHYSICIAN_INPUT_CLEAN':
            return {
                ...state,
                physicianInput: {
                    ...state.physicianInput,
                    dirty: false,
                },
            };
        default:
            return state;
    }
};
