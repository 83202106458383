import React from 'react';

const PilotProgramSection = () => {
    return (
        <section className="section pilot-section">
            <div className="text-row">
                <div className="text-column">
                    <h2>Join Our Pilot Program!</h2>
                    <h3>Get three months of Doq Copilot free.</h3>
                </div>
                <div className="text-column">
                    <button>Sign up</button>
                </div>
            </div>
        </section>
    );
};

export default PilotProgramSection;
