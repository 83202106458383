import React from 'react';
import Header from '../Header';
import { PatientProvider } from './PatientContext';
import './Dashboard.css';
import { RefreshSuggestionProvider } from '../../helpers/RefreshSuggestions';

export function DashboardContainer({ children }) {
    return (
        <PatientProvider>
            <RefreshSuggestionProvider>
                <div className="dashboard">
                    <Header
                        showPatientSelector={true}
                        showAccountDetails={true}
                    />
                    {children}
                </div>
            </RefreshSuggestionProvider>
        </PatientProvider>
    );
}
