import React, { useState } from 'react';

export const ArbeitsdiagnoseToolbox = () => {
    const [tool, setTool] = useState('tool1');

    const handleToolSelection = (toolSelection) => {
        setTool(toolSelection);
    };

    return (
        <div className="toolbox-content">
            <div className="tool-selector">
                <button
                    className="tool1"
                    onClick={() => handleToolSelection('tool1')}
                >
                    Tool 1
                </button>
                <button
                    className="tool2"
                    onClick={() => handleToolSelection('tool2')}
                >
                    Tool 2
                </button>
            </div>
            <div className="workspace">
                {tool == 'tool1' && <p>Tool1</p>}
                {tool == 'tool2' && <p>Tool2</p>}
            </div>
        </div>
    );
};
