import React, { useState } from 'react';
import InputField from '../../../helpers/InputField';

const FeaturesSection = () => {
    const [email, setEmail] = useState('');

    const handleEmailChange = (value) => {
        setEmail(value);
    };

    return (
        <section className="section features-section">
            <div className="text-row">
                <h2>Choose your new assistant</h2>
            </div>
            <div className="image-row">
                <div className="image-column">
                    <div className="package-description">
                        <h2>Doq Copilot</h2>
                        <br></br>
                        <p>
                            The digital assistant you&#39;ve been waiting for.
                            Save up to two hours a day by automating
                            documentation with Doq Copilot&#39;s intuitive
                            dashboard for physicians.
                        </p>
                        <div className="features-list">
                            <ul>
                                <li>Contexual Voice Transcription</li>
                                <li>Automated Notetaking</li>
                                <li>
                                    Anamnesis, Diagnosis and Epicrisis Writing
                                </li>
                                <li>Intuitive Copy-Paste EMR Integration</li>
                                <li>Inline Text Editing and Translation</li>
                            </ul>
                        </div>
                        <div className="price-container">
                            <h2>$129</h2>
                            <p>/mo</p>
                        </div>
                        <div className="package-description">
                            <p>
                                Registering takes less than 3 minutes. Clients
                                registered before 31/01/2025 will receive two
                                months free.
                            </p>
                        </div>
                        <button>Register</button>
                    </div>
                </div>
                <div className="image-column">
                    <div className="package-description">
                        <h2>Doq Copilot V2</h2>
                        <br></br>
                        <p>
                            Elevate the flow of your entire practice with the
                            newest technology the industry has ever seen. Doq
                            Copilot V2 maximizes quality and profitability for
                            everyone on staff.
                        </p>
                        <div className="features-list">
                            <ul>
                                <li>All features of Doq Copilot V1</li>
                                <li>Automated Patient Triage</li>
                                <li>Practice Management Dashboard</li>
                                <li>Diagnosis & Treatment Suggestions</li>
                                <li>Lab Results Analysis</li>
                            </ul>
                        </div>
                        <div className="price-container">
                            <h2>Pre-Order Now</h2>
                        </div>
                        <div className="package-description">
                            <p>
                                Registering takes less than 3 minutes. Clients
                                registered before 31/01/2025 will receive two
                                months free.
                            </p>
                        </div>
                        <div className="signup-container">
                            <InputField
                                value={email}
                                placeholder="Email"
                                onChange={handleEmailChange}
                            />
                            <button>Pre-Order</button>
                        </div>
                    </div>
                </div>
            </div>
            {/*<section className="section features-section">
            <h2>Key Features</h2>
            <div className="text-row">
                <div className="text-column subheading">
                    <ul>
                        <h3>
                            <li>Time-Saving Documentation</li>
                            <li>Customizable Templates</li>
                            <li>Seamless EHR Integration</li>
                            <li>Automated Clinical Summaries</li>
                        </h3>
                    </ul>
                </div>
                <div className="text-column subheading">
                    <ul>
                        <h3>
                            <li>Secure Data Storage</li>
                            <li>Mobile Access Access</li>
                            <li>Collaboration Tools</li>
                            <li>AI-Powered Assistance</li>
                        </h3>
                    </ul>
                </div>
            </div>
        </section>*/}
        </section>
    );
};

export default FeaturesSection;
