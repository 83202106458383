// src/RegistrationForm.js
import React, { useState } from 'react';
import './RegistrationForm.css';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { signUpClicked } from './signup';

const RegistrationForm = () => {
    const [formData, setFormData] = useState({
        title: '',
        firstName: '',
        lastName: '',
        role: '',
        email: '',
        password: '',
        organizationId: '',
    });
    const { t } = useTranslation();
    const navigate = useNavigate();

    const handleTitleSelected = (e) => {
        setFormData({ ...formData, title: e.target.value });
    };

    const handleRoleSelected = (e) => {
        setFormData({ ...formData, role: e.target.value });
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        signUpClicked(formData);
    };

    const handleBack = () => {
        navigate('/login');
        //later add an alert that warns the user they will lose the data they've already entered
    };

    return (
        <div className="registration-page">
            <div className="registration-form-container">
                <div className="back-button">
                    <button onClick={handleBack}>Back</button>
                </div>
                <h2>{t('register')}</h2>
                <form onSubmit={handleSubmit}>
                    <div className="entry-container">
                        <label>
                            {t('title')}
                            <br />
                            <select
                                value={formData.title}
                                onChange={handleTitleSelected}
                            >
                                <option value=""></option>
                                <option value="Mr.">{t('mr')}</option>
                                <option value="Mrs.">{t('mrs')}</option>
                                <option value="Ms.">{t('ms')}</option>
                                <option value="Dr. med.">{t('drMed')}</option>
                                <option value="Prof.">{t('prof')}</option>
                            </select>
                        </label>
                    </div>
                    <div className="entry-container">
                        <label>
                            {t('firstName')}
                            <br />
                            <input
                                type="text"
                                name="firstName"
                                placeholder={t('enterFirstName')}
                                value={formData.firstName}
                                onChange={handleChange}
                                required
                            />
                        </label>
                    </div>
                    <div className="entry-container">
                        <label>
                            {t('lastName')}
                            <br />
                            <input
                                type="text"
                                name="lastName"
                                placeholder={t('enterLastName')}
                                value={formData.lastName}
                                onChange={handleChange}
                                required
                            />
                        </label>
                    </div>
                    <div className="entry-container">
                        <label>
                            {t('role')}
                            <br />
                            <select
                                value={formData.role}
                                onChange={handleRoleSelected}
                            >
                                <option value=""></option>
                                <option value="physician">
                                    {t('physician')}
                                </option>
                                <option value="receptionist">
                                    {t('receptionist')}
                                </option>
                                <option value="station">
                                    {t('hospitalPhysician')}
                                </option>
                            </select>
                        </label>
                    </div>
                    <div className="entry-container">
                        <label>
                            {t('email')}
                            <br />
                            <textarea
                                name="email"
                                placeholder={t('enterEmail')}
                                value={formData.email}
                                onChange={handleChange}
                            />
                        </label>
                    </div>
                    <div className="password-container">
                        <label>
                            {t('password')}
                            <br />
                            <textarea
                                name="password"
                                placeholder={t('enterPassword')}
                                value={formData.password}
                                onChange={handleChange}
                            />
                        </label>
                    </div>
                    <div className="entry-container">
                        <label>
                            {t('organizationId')}
                            <br />
                            <textarea
                                name="organizationId"
                                placeholder={t('enterOrganizationId')}
                                value={formData.organizationId}
                                onChange={handleChange}
                            />
                        </label>
                    </div>
                    <div className="submit-button">
                        <button type="submit">{t('register')}</button>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default RegistrationForm;
