import React from 'react';
import './ImageCard.css';

const ImageCard = ({ image, description }) => {
    return (
        <div
            className="image-card"
            style={{ backgroundImage: `url(${image})` }}
        >
            {/*<div className="overlay">
                <h2>{heading}</h2>
                <h4>{subheading}</h4>
            </div>*/}
            <div className="description">
                <p>{description}</p>
            </div>
        </div>
    );
};

export default ImageCard;
