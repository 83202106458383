import React, { useState } from 'react';

export const NeurologischeUntersuchung = () => {
    const [intakeFindings, setIntakeFindings] = useState('');

    const handleIntakeFindingsChange = (event) => {
        setIntakeFindings(event.target.value);
    };

    return (
        <div className="workspace">
            <div className="scroll-container">
                <div className="findings-card">
                    <div className="findings-header">
                        <div className="heading">Bewusstsein</div>
                        <button className="generate-button">
                            Normalbefund
                        </button>
                    </div>
                    <div className="findings-content"></div>
                </div>
                <div className="findings-card">
                    <div className="findings-header">
                        <div className="heading">Kopf & Meningismus</div>
                        <button className="generate-button">
                            Normalbefund
                        </button>
                    </div>
                    <div className="findings-content"></div>
                </div>
                <div className="findings-card">
                    <div className="findings-header">
                        <div className="heading">
                            Gesichtsfeld (N. Opticus II)
                        </div>
                        <button className="generate-button">
                            Normalbefund
                        </button>
                    </div>
                    <div className="findings-content"></div>
                </div>
                <div className="findings-card">
                    <div className="findings-header">
                        <div className="heading">
                            Pupillenmotorik (N. Oculomotorius III)
                        </div>
                        <button className="generate-button">
                            Normalbefund
                        </button>
                    </div>
                    <div className="findings-content"></div>
                </div>
                <div className="findings-card">
                    <div className="findings-header">
                        <div className="heading">
                            Augenmotorik (N. Oculomotorius III, N. Trochlearis
                            IV, N. Abducens VI)
                        </div>
                        <button className="generate-button">
                            Normalbefund
                        </button>
                    </div>
                    <div className="findings-content"></div>
                </div>
                <div className="findings-card">
                    <div className="findings-header">
                        <div className="heading">Übrige Hirnnerven</div>
                        <button className="generate-button">
                            Normalbefund
                        </button>
                    </div>
                    <div className="findings-content"></div>
                </div>
                <div className="findings-card">
                    <div className="findings-header">
                        <div className="heading">Artikulation & Schlucken</div>
                        <button className="generate-button">
                            Normalbefund
                        </button>
                    </div>
                    <div className="findings-content"></div>
                </div>
                <div className="findings-card">
                    <div className="findings-header">
                        <div className="heading">Neuropsychologie</div>
                        <button className="generate-button">
                            Normalbefund
                        </button>
                    </div>
                    <div className="findings-content"></div>
                </div>
                <div className="findings-card">
                    <div className="findings-header">
                        <div className="heading">
                            Kraft, Eigen- & Fremdreflexe
                        </div>
                        <button className="generate-button">
                            Normalbefund
                        </button>
                    </div>
                    <div className="findings-content"></div>
                </div>
                <div className="findings-card">
                    <div className="findings-header">
                        <div className="heading">Pyramidenbahnzeichen</div>
                        <button className="generate-button">
                            Normalbefund
                        </button>
                    </div>
                    <div className="findings-content"></div>
                </div>
                <div className="findings-card">
                    <div className="findings-header">
                        <div className="heading">
                            Koordination, Stand & Gang
                        </div>
                        <button className="generate-button">
                            Normalbefund
                        </button>
                    </div>
                    <div className="findings-content"></div>
                </div>
                <div className="findings-card">
                    <div className="findings-header">
                        <div className="heading">Sensibilität</div>
                        <button className="generate-button">
                            Normalbefund
                        </button>
                    </div>
                    <div className="findings-content"></div>
                </div>
                <div className="findings-card">
                    <div className="findings-header">
                        <div className="heading">Unwillkürliche Bewegungen</div>
                        <button className="generate-button">
                            Normalbefund
                        </button>
                    </div>
                    <div className="findings-content"></div>
                </div>
            </div>
            <div className="findings-summary-card">
                <div className="record-field">
                    <button className="generate-button">
                        Aufnahmebefund Generieren
                    </button>
                    <textarea
                        type="text"
                        placeholder="Internistischer Aufnahmebefund"
                        value={intakeFindings}
                        onChange={handleIntakeFindingsChange}
                    />
                </div>
            </div>
        </div>
    );
};
