import axios from 'axios';
import { signOut } from 'supertokens-auth-react/recipe/session';

import { serverUrl } from '../../config';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { throwNamedError } from '../../components/Header/GlobalErrorDisplay';

console.log('Server URL:', serverUrl);

// Note that we currently export this method.
// However, if you want error tracking
// (re-login on HTTP 401, error message display),
// then use the `useRESTRequest()` hook instead.
export async function makeRESTRequest(method, path, body = null, config = {}) {
    const url = `${serverUrl}${path[0] === '/' ? '' : '/'}${path}`;

    const defaultHeaders = { authorization: localStorage.getItem('token') };
    const headers = config.headers
        ? { ...defaultHeaders, ...config.headers }
        : defaultHeaders;

    let response;

    try {
        response = await axios.request(url, {
            method,
            headers,
            params: { language: config.language },
            data: body,
        });
    } catch (error) {
        if (error.code === 'ERR_NETWORK') {
            throwNamedError('error.network');
        } else if (error?.response?.status === 401) {
            config.logout?.();
        } else {
            throw error;
        }
    }

    return response?.data;
}

export function useRESTRequest() {
    const { i18n } = useTranslation();
    const navigate = useNavigate();

    const language = {
        en: 'English',
        de: 'German',
    }[i18n.resolvedLanguage];

    const wrapped = (method, path, body = null, options = {}) => {
        return makeRESTRequest(method, path, body, {
            navigate,
            language,
            logout:
                (options.autoLogout ?? true) &&
                (async () => {
                    localStorage.removeItem('token');
                    await signOut();
                    navigate('/login');
                }),
        });
    };

    return useCallback(wrapped, [navigate, language]);
}
