import React, { useState } from 'react';

export const InternistischeUntersuchung = () => {
    const [intakeFindings, setIntakeFindings] = useState('');

    const handleIntakeFindingsChange = (event) => {
        setIntakeFindings(event.target.value);
    };

    return (
        <div className="workspace">
            <div className="scroll-container">
                <div className="findings-card">
                    <div className="findings-header">
                        <div className="heading">
                            Allgemein- & Ernährungszustand
                        </div>
                        <button className="generate-button">
                            Normalbefund
                        </button>
                    </div>
                    <div className="findings-content"></div>
                </div>
                <div className="findings-card">
                    <div className="findings-header">
                        <div className="heading">Haut & Schleimhaut</div>
                        <button className="generate-button">
                            Normalbefund
                        </button>
                    </div>
                    <div className="findings-content"></div>
                </div>
                <div className="findings-card">
                    <div className="findings-header">
                        <div className="heading">Schilddrüse, Mund, Lunge</div>
                        <button className="generate-button">
                            Normalbefund
                        </button>
                    </div>
                    <div className="findings-content"></div>
                </div>
                <div className="findings-card">
                    <div className="findings-header">
                        <div className="heading">Thorax & Lunge</div>
                        <button className="generate-button">
                            Normalbefund
                        </button>
                    </div>
                    <div className="findings-content"></div>
                </div>
                <div className="findings-card">
                    <div className="findings-header">
                        <div className="heading">Herz</div>
                        <button className="generate-button">
                            Normalbefund
                        </button>
                    </div>
                    <div className="findings-content"></div>
                </div>
                <div className="findings-card">
                    <div className="findings-header">
                        <div className="heading">Abdomen</div>
                        <button className="generate-button">
                            Normalbefund
                        </button>
                    </div>
                    <div className="findings-content"></div>
                </div>
                <div className="findings-card">
                    <div className="findings-header">
                        <div className="heading">Leber & Milz</div>
                        <button className="generate-button">
                            Normalbefund
                        </button>
                    </div>
                    <div className="findings-content"></div>
                </div>
                <div className="findings-card">
                    <div className="findings-header">
                        <div className="heading">Nieren</div>
                        <button className="generate-button">
                            Normalbefund
                        </button>
                    </div>
                    <div className="findings-content"></div>
                </div>
                <div className="findings-card">
                    <div className="findings-header">
                        <div className="heading">Lymphknoten</div>
                        <button className="generate-button">
                            Normalbefund
                        </button>
                    </div>
                    <div className="findings-content"></div>
                </div>
                <div className="findings-card">
                    <div className="findings-header">
                        <div className="heading">Pulsstatus</div>
                        <button className="generate-button">
                            Normalbefund
                        </button>
                    </div>
                    <div className="findings-content"></div>
                </div>
                <div className="findings-card">
                    <div className="findings-header">
                        <div className="heading">Ödeme</div>
                        <button className="generate-button">
                            Normalbefund
                        </button>
                    </div>
                    <div className="findings-content"></div>
                </div>
                <div className="findings-card">
                    <div className="findings-header">
                        <div className="heading">Patient Verweigert</div>
                        <button className="generate-button">
                            Normalbefund
                        </button>
                    </div>
                    <div className="findings-content"></div>
                </div>
            </div>
            <div className="findings-summary-card">
                <div className="record-field">
                    <button className="generate-button">
                        Aufnahmebefund Generieren
                    </button>
                    <textarea
                        type="text"
                        placeholder="Internistischer Aufnahmebefund"
                        value={intakeFindings}
                        onChange={handleIntakeFindingsChange}
                    />
                </div>
            </div>
        </div>
    );
};
