import React from 'react';
import { useTranslation } from 'react-i18next';
import './AnamnesisColumn.css';
import { OutputCard } from '../Dashboard/OutputCard';
import { DialogueCard } from './Dialogue/DialogueCard';

export const AnamnesisColumn = () => {
    const { t } = useTranslation();

    const intakeFormActive = false;

    return (
        <div className="patient-intake-column">
            {intakeFormActive && (
                <>
                    <div className="card">
                        <div className="patient-intake-form-card">
                            <OutputCard
                                showHeader={true}
                                title="Patient Intake Form"
                                category="conversations"
                            />
                        </div>
                    </div>
                </>
            )}
            <div className="card dialogue-card">
                <DialogueCard />
            </div>
            <div className="card anamnesis-card">
                <OutputCard
                    showHeader={true}
                    title={t('anamnesis')}
                    category="anamnesis"
                />
            </div>
            <div className="card physical-examination-card">
                <OutputCard
                    showHeader={true}
                    title={t('physicalExamination')}
                    category="physical_examination"
                />
            </div>
        </div>
    );
};
