import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

i18n
    // detect user language
    // learn more: https://github.com/i18next/i18next-browser-languageDetector
    .use(LanguageDetector)
    // pass the i18n instance to react-i18next.
    .use(initReactI18next)
    // Custom module to provide stack traces
    // when we ask for weird translations.
    // Useful for determining which component is translating some dynamic key.
    .use({
        type: '3rdParty',
        init: (i18n) => {
            const f = i18n.t;
            i18n.t = (...args) => {
                const text = args[0];

                if (text === '') {
                    console.trace('Trying to translate empty string');
                }

                return f(...args);
            };
        },
    })
    // init i18next
    // for all options read: https://www.i18next.com/overview/configuration-options
    .init({
        debug: true,
        fallbackLng: 'en',
        interpolation: {
            escapeValue: false, // not needed for react as it escapes by default
        },
        resources: {
            en: {
                translation: require('./translations/en.json'),
            },
            de: {
                translation: require('./translations/de.json'),
            },
        },
    });

export default i18n;
