import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import './CopyIcon.css';

const COPY_FADE_MILLISECONDS = 5000;

// NOTE: It would be nice if copying one box
// cleared all other copy texts,
// but setting that up requires some extra state smuggling.
export function useCopy({ copyText }) {
    const [copiedRecently, setCopiedRecently] = useState(false);
    const timeoutRef = useRef(0);

    // Every time `copyText` changes, reset `copiedRecently`.
    useEffect(() => {
        setCopiedRecently(false);
    }, [copyText]);

    // When the copy button is clicked,
    // show the copy text and then remove it after a few seconds.
    const handleClickCopy = () => {
        // `copyText` is either a value or a function.
        // Reduce this down to a value.
        const text = typeof copyText === 'function' ? copyText() : copyText;
        navigator.clipboard.writeText(text);
        clearTimeout(timeoutRef.current);
        setCopiedRecently(true);
        timeoutRef.current = setTimeout(() => {
            setCopiedRecently(false);
        }, COPY_FADE_MILLISECONDS);
    };

    return { copiedRecently, handleClickCopy };
}

export const CopyIcon = ({ copyText, between }) => {
    const { copiedRecently, handleClickCopy } = useCopy({ copyText });
    const { t } = useTranslation();

    let labelClassName = 'copy-icon-label';
    if (!copiedRecently) labelClassName += ' invisible';

    // We wrap these two components in the empty <> component
    // such that they are flattened into the parent,
    // which is assumed to have { display: flex; }.
    return (
        <>
            <span className={labelClassName}>{t('copied')}</span>
            {between}
            <img
                src="/copy-icon.svg"
                className="copy-icon"
                onClick={handleClickCopy}
            />
        </>
    );
};
