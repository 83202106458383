// <AudioRecorder> component to handle streaming audio recording.
// Not responsible for any kind of server logic.
// Since this component generalizes over streaming
// and non-streaming use-cases, it is best to use
// one of the wrappers such as <RecordButton>
// or <TranscribeButton>, which implement
// per-chunk handlers so you don't have to think about it.

import { useEffect, useRef, useState } from 'react';
import {
    FaFloppyDisk,
    FaMicrophone,
    FaPause,
    FaPlay,
    FaX,
} from 'react-icons/fa6';
import './AudioRecorder.css';
import {
    STATE_INACTIVE,
    STATE_PAUSED,
    STATE_RECORDING,
} from './ChunkedMediaRecorder';
import LoadingIcon from './LoadingIcon.helpers';
import { useTimer, useTimerCurrentTime } from './useTimer';

// Extra state (not present in MediaRecorder),
// used when we are displaying a loading icon.
const STATE_PROCESSING = 'processing';

export function useAudioRecorder() {} // For compatibility reasons.

// Given a function that checks the current (relative)
// time in milliseconds, display a timer.
function Timer({ getTimeMs }) {
    const ms = useTimerCurrentTime({
        getTimeMs,
        pollMs: 500,
    });

    const totalSeconds = Math.floor(ms / 1_000);
    const minutes = Math.floor(totalSeconds / 60);
    const seconds = totalSeconds - minutes * 60;

    const displayText =
        String(minutes).padStart(2, '0') +
        ':' +
        String(seconds).padStart(2, '0');

    return <span>{displayText}</span>;
}

function PlayPauseButton({ onClick, state }) {
    const IconClass = state === STATE_RECORDING ? FaPause : FaPlay;

    return <button onClick={onClick}>{<IconClass />}</button>;
}

// Audio recorder component, which gets to assume
// that mediaRecorder has already been acquired.
function AudioRecorderContents({
    mediaRecorder,
    handleRecording,
    timeSliceMs,
}) {
    // undefined | "RECORDING" | "PAUSED".
    // `state===undefined` indicates no recording active.
    const [state, setState] = useState(STATE_INACTIVE);

    // Whenever the media recorder is started/paused/stopped,
    // this timer should also be started/paused/stopped.
    const timer = useTimer();

    const handlersRef = useRef();

    // When data becomes available, call the `onChunkComplete()` function.
    // This happens every `timeSliceMs` milliseconds, or when recording ends.
    // If `timeSliceMs===undefined`,
    // then `onChunkComplete()` is only called once.
    useEffect(() => {
        const handleDataAvailable = (ev) => {
            handlersRef.current?.onChunkComplete(ev.data);
        };
        const handleStop = () => {
            const promise = handlersRef.current?.onRecordingComplete();
            if (promise) {
                setState(STATE_PROCESSING);
                promise.finally(() => {
                    setState(STATE_INACTIVE);
                });
            } else {
                setState(STATE_INACTIVE);
            }
        };

        mediaRecorder.addEventListener('dataavailable', handleDataAvailable);
        mediaRecorder.addEventListener('stop', handleStop);

        return () => {
            mediaRecorder.removeEventListener(
                'dataavailable',
                handleDataAvailable
            );
            mediaRecorder.removeEventListener('stop', handleStop);
        };
    }, [mediaRecorder]);

    const handleClickPlayPause = () => {
        if (state === STATE_RECORDING) {
            timer.pause();
            mediaRecorder.pause();
            setState(STATE_PAUSED);
        } else if (state === STATE_PAUSED) {
            mediaRecorder.resume();
            timer.resume();
            setState(STATE_RECORDING);
        }
    };

    const handleClickCancel = () => {
        // Ensure that callbacks do not fire
        handlersRef.current = undefined;
        // Stop the media recorder
        mediaRecorder.stop();
        // Close the component.
        setState(STATE_INACTIVE);
    };

    const handleClickSave = () => {
        mediaRecorder.stop();
    };

    const handleClickRecord = () => {
        mediaRecorder.start(timeSliceMs);
        timer.start();
        // Update the event handlers with a fresh invocation.
        handlersRef.current = handleRecording();
        setState(STATE_RECORDING);
    };

    return (
        <span
            className={`audio-recorder ${
                [STATE_RECORDING, STATE_PAUSED].includes(state) ? 'active' : ''
            }`}
        >
            {[STATE_RECORDING, STATE_PAUSED].includes(state) ? (
                <>
                    <button onClick={handleClickCancel}>
                        <FaX />
                    </button>
                    <Timer getTimeMs={timer.current} />
                    <PlayPauseButton
                        state={state}
                        onClick={handleClickPlayPause}
                    />
                    <button onClick={handleClickSave}>
                        <FaFloppyDisk />
                    </button>
                </>
            ) : (
                <button onClick={handleClickRecord}>
                    <FaMicrophone />
                </button>
            )}
            {state === STATE_PROCESSING && <LoadingIcon />}
        </span>
    );
}

// Only render <AudioRecorderContents> once the media recorder is ready.
// This means that we don't have to check for `mediaRecorder===undefined`
// within <AudioRecorderContents>, which is nice.
export function AudioRecorder({
    MediaRecorderClass = MediaRecorder,
    ...props
}) {
    const [mediaRecorder, setMediaRecorder] = useState();

    useEffect(() => {
        navigator.mediaDevices.getUserMedia({ audio: true }).then((stream) => {
            setMediaRecorder(
                new MediaRecorderClass(stream, { mimeType: 'audio/webm' })
            );
        });
    }, [MediaRecorderClass]);

    return (
        mediaRecorder && (
            <AudioRecorderContents mediaRecorder={mediaRecorder} {...props} />
        )
    );
}
