import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import LoadingIcon from 'helpers/LoadingIcon.helpers';
import { useApiQuery } from 'helpers/useApiQuery';
import { PatientContext } from 'components/Dashboard/PatientContext';
import { DialogueContext } from './DialogueContext';
import './DialogueTranscription.css';

// Given [x1,x2,x3], get [f(x1,0),g(0),f(x2,1), g(1), ...]
// Useful for adding row barriers in grids.
function betweenMap(items, itemFunc, betweenFunc) {
    const newItems = [];
    for (let i = 0; i < items.length; ++i) {
        newItems.push(itemFunc(items[i], i));
        newItems.push(betweenFunc(i));
    }
    newItems.pop(); // Final barrier should be removed.

    return newItems;
}

function disabledClassName(root, disabled) {
    let className = root;
    if (disabled) {
        className += ` ${root}-disabled`;
    }
    return className;
}

function DialogueTranscriptionItem({ transcript, onChangeEnabled, enabled }) {
    const { t } = useTranslation();

    const disabled = !enabled;

    const handleClickToggle = () => {
        onChangeEnabled(!enabled);
    };

    return (
        <>
            <div
                key={transcript}
                className={disabledClassName(
                    'dialogue-transcript-item',
                    disabled
                )}
            >
                {transcript}
            </div>
            <div
                className={disabledClassName('dialogue-grid-button', disabled)}
            >
                <button onClick={handleClickToggle}>
                    {t(enabled ? 'remove' : 'keep')}
                </button>
            </div>
        </>
    );
}

// TODO do we still need to hold this hook in the parent?
export function useDialogueTranscription() {
    const { serverResponse, setServerResponse } = useContext(DialogueContext);

    const [localTranscriptItemsState, setLocalTranscriptItems] = useState([]);
    const query = useApiQuery();

    // When the server response is updated,
    // update the transcript items.
    useEffect(() => {
        const data = serverResponse;
        if (data) {
            // When fetched, each transcript item is enabled.
            const transcriptItems = data.transcript.map((transcript) => ({
                transcript,
                enabled: true,
            }));
            setLocalTranscriptItems(transcriptItems);
        }
    }, [serverResponse, setLocalTranscriptItems]);

    // State that represents whether there are unsaved changes.
    // In this case, changes are transcript items marked for deletion.
    const [dirty, setDirty] = useState(false);

    const { state } = useContext(PatientContext);

    const localTranscriptItems = localTranscriptItemsState;

    // Function to remove a transcript from the local data.
    // Does not persist automatically.
    const handleChangeEnabled = (transcript) => (enabled) => {
        setLocalTranscriptItems(
            localTranscriptItems.map((x) =>
                x.transcript === transcript ? { ...x, enabled } : x
            )
        );
        setDirty(true);
    };

    const resetTranscriptItems = () => {
        setLocalTranscriptItems(serverResponse?.transcript);
    };

    const applyChanges = () => {
        const body = {
            transcript: localTranscriptItems
                .filter((x) => x.enabled)
                .map((x) => x.transcript),
        };

        query.submit({
            method: 'PUT',
            path: `/dialogue/transcript/${state.patientId}`,
            body: body,
            callback: (data) => {
                if (data) {
                    setServerResponse(data);
                    setDirty(false);
                }
            },
        });
    };

    return {
        localTranscriptItems,
        handleChangeEnabled,
        resetTranscriptItems,
        dirty,
        applyChanges,
        queryIsLoading: query.isLoading,
    };
}

// Component to render dialogue transcription.
// Requires a `useDialogueTranscription()` hook,
// which is passed in by the parent component.
export function DialogueTranscription({ hook }) {
    const { handleChangeEnabled, localTranscriptItems } = hook;

    return (
        <div className="dialogue-transcript">
            {betweenMap(
                localTranscriptItems,
                ({ transcript, enabled }) => (
                    <DialogueTranscriptionItem
                        key={transcript}
                        transcript={transcript}
                        enabled={enabled}
                        onChangeEnabled={handleChangeEnabled(transcript)}
                    />
                ),
                (idx) => (
                    <hr key={idx} className="dialogue-divider" />
                )
            )}
        </div>
    );
}

export function DialogueTranscriptionHeaderElements({ hook }) {
    const { t } = useTranslation();

    const handleClickApplyRemovals = () => {
        hook.applyChanges();
    };

    return (
        <>
            {hook.dirty && (
                <button onClick={handleClickApplyRemovals}>
                    {t('applyRemovals')}
                </button>
            )}
            {hook.queryIsLoading && <LoadingIcon />}
        </>
    );
}
