import React, { useState } from 'react';
import './AccountDetails.css';
import { FaUserDoctor } from 'react-icons/fa6';
import { useTranslation } from 'react-i18next';
import { useUserInfo } from '../../../helpers/useUserInfo';

export const AccountDetails = ({ onLogout }) => {
    const userInfo = useUserInfo();
    const [isDropdownOpen, setDropdownOpen] = useState(false);
    const { t } = useTranslation();

    const toggleDropdown = () => {
        setDropdownOpen(!isDropdownOpen);
    };

    const handleOptionClick = (option) => {
        console.log(`Selected option: ${option}`);
        setDropdownOpen(false); // Close the dropdown after selecting an option
    };

    const translatedRole = userInfo.role && t(userInfo.role);

    const labelFromUserInfo = () => {
        const capitalizeFirstLetter = (string) => {
            return string.charAt(0).toUpperCase() + string.slice(1);
        };

        return (
            <>
                <p>{`${userInfo.title} ${userInfo.firstName} ${userInfo.lastName}`}</p>
                <p>{capitalizeFirstLetter(translatedRole)}</p>
            </>
        );
    };

    return (
        <div className="account-details-container">
            <button className="account-details-button" onClick={toggleDropdown}>
                <FaUserDoctor />
                <div className="account-content">{labelFromUserInfo()}</div>
            </button>
            {isDropdownOpen && (
                <div className="dropdown-menu">
                    <button onClick={() => handleOptionClick('option1')}>
                        {t('preferences')}
                    </button>
                    <button onClick={() => handleOptionClick('option2')}>
                        {t('accountInfo')}
                    </button>
                    <button onClick={() => handleOptionClick('option3')}>
                        {t('help')}
                    </button>
                    <button onClick={onLogout}>{t('logOut')}</button>
                </div>
            )}
        </div>
    );
};
