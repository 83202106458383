// Provider/hook combo to accomplish the following:
// <PersistProvider>
//   <SomeNestedComponents>
//     <ImportantComponent>
//
// Where important component has some data that needs
// to be persisted/saved when it is closed/removed.
// And doing so on unmount is hard to debug, so we do this explicitly.

import { MakeCallbacks } from './useCallbacks';

const callbacks = MakeCallbacks('PersistProvider');
export const PersistProvider = callbacks.Provider;
export const usePersistCallback = callbacks.useCallback;
export const usePersistTrigger = callbacks.useTrigger;
