import React, { useState } from 'react';
import './LoginContainer.css';
import { FaUserDoctor } from 'react-icons/fa6';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useUserInfo } from '../../../../../helpers/useUserInfo';

export const LoginContainer = () => {
    const userInfo = useUserInfo();
    const [isDropdownOpen, setDropdownOpen] = useState(false);
    const [isLoggedIn, setLoggedIn] = useState(false);
    const [loadTrigger, setLoadTrigger] = useState(0);
    const { t } = useTranslation();
    const navigate = useNavigate();

    const toggleDropdown = () => {
        setDropdownOpen(!isDropdownOpen);
    };

    const handleOptionClick = (option) => {
        console.log(`Selected option: ${option}`);
        setDropdownOpen(false); // Close the dropdown after selecting an option
    };

    const translatedRole = userInfo.role && t(userInfo.role);

    const labelFromUserInfo = () => {
        const capitalizeFirstLetter = (string) => {
            return string.charAt(0).toUpperCase() + string.slice(1);
        };

        return (
            <>
                <p>{`${userInfo.title} ${userInfo.firstName} ${userInfo.lastName}`}</p>
                <p>{capitalizeFirstLetter(translatedRole)}</p>
            </>
        );
    };

    const handleLoginRedirection = () => {
        if (!isLoggedIn) {
            navigate('/dashboard');
        } else {
            setLoadTrigger(loadTrigger + 1);
        }
    };

    const handleLogout = () => {
        localStorage.removeItem('token');
        setLoggedIn(false);
    };

    return (
        <div className="login-container">
            {!isLoggedIn && (
                <>
                    <button
                        className="log-in-button"
                        onClick={handleLoginRedirection}
                    >
                        Log In
                    </button>
                </>
            )}
            {isLoggedIn && (
                <>
                    <button
                        className="logged-in-button"
                        onClick={toggleDropdown}
                    >
                        <FaUserDoctor />
                        <div className="account-content">
                            {labelFromUserInfo()}
                        </div>
                    </button>
                    {isDropdownOpen && (
                        <div className="dropdown-menu">
                            <button
                                onClick={() => handleOptionClick('option1')}
                            >
                                {t('preferences')}
                            </button>
                            <button
                                onClick={() => handleOptionClick('option2')}
                            >
                                {t('accountInfo')}
                            </button>
                            <button
                                onClick={() => handleOptionClick('option3')}
                            >
                                {t('help')}
                            </button>
                            <button onClick={handleLogout}>
                                {t('logOut')}
                            </button>
                        </div>
                    )}
                </>
            )}
        </div>
    );
};
