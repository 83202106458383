import React from 'react';

const FAQSection = () => {
    return (
        <section className="section faq-section">
            <h2>Frequently Asked Questions</h2>
            <p>
                <strong>Q1:</strong> Your question here?
            </p>
            <p>
                <strong>A1:</strong> Your answer here...
            </p>
        </section>
    );
};

export default FAQSection;
