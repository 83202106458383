import { useState, useEffect } from 'react';
import { useRESTRequest } from '../components/Dashboard/serverUtils';

export function useUserInfo() {
    const [userInfo, setUserInfo] = useState({
        email: '',
        title: '',
        firstName: '',
        lastName: '',
        role: '',
    });
    const makeRESTRequest = useRESTRequest();

    useEffect(() => {
        const fetchUserInfo = async () => {
            // No need to pass the email through to this route,
            // as the user email is implied by the user
            // associated with the session.
            const updatedUserInfo = await makeRESTRequest(
                'GET',
                `/users/myInfo/me`
            );
            if (updatedUserInfo) {
                setUserInfo((prevState) => ({
                    ...prevState,
                    title: updatedUserInfo.title,
                    firstName: updatedUserInfo.firstName,
                    lastName: updatedUserInfo.lastName,
                }));
            } else {
                console.warn('updatedUserInfo===null');
            }
        };

        fetchUserInfo();
    }, [makeRESTRequest]);

    return userInfo;
}
